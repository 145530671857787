import { Modal, Button } from 'antd';
import termsIcon from '../../images/Haleon/Paper.png';
import { useTranslation } from 'react-i18next';

const TermsTNG = ({ termsTNG, setTermsTNG }) => {
	const { t } = useTranslation();
	return (
		<Modal open={termsTNG} centered footer={null} closable={false}>
			<div className="text-center">
				<img style={{ width: '18%' }} src={termsIcon} alt="T&C_img" />
				<div className="mt-1" style={{ lineHeight: '25px' }}>
					<h3 className="mt-2" style={{ fontSize: '20px', fontFamily: 'Hero New' }}>
						{t('Terms & Conditions')}
					</h3>
				</div>
				<ul
					style={{
						padding: '0 15px',
						fontSize: '14px',
						textAlign: 'justify',
						textJustify: 'auto',
						fontFamily: 'Hero New',
					}}
				>
					<li className="my-1">
						{' '}
						{t('Only ONE (1) Reload PIN Reward can be used per reload.')}
					</li>
					<li className="my-1">
						{t(
							"To utilize the Reload Pin, log in on the microsite. At 'Rewards', tap on the 'View Now' button under your rewards. Then, tap on the copy icon next to the Voucher Code of the respective rewards and follow the steps provided."
						)}
					</li>
					<li className="my-1">
					{t('Used Reload PIN Reward are non-reversible. Once used, the amount is not exchangeable nor refundable for credits, cash or such replacement in part or in full.')}
					</li>
					<li className="my-1">
					{t('TNG Digital will not in any way contact users for any personal information or account credentials for this Campaign.')}
					</li>
					<li className="my-1">
						{t('Reload Pin will be expired on 31st December 2024.')}
					</li>
				</ul>
				<Button
					type="primary"
					className="mt-1"
					onClick={() => setTermsTNG(false)}
					style={{
						fontSize: '16px',
						borderRadius: '8px',
						background: '#CB0E07',
						color: 'black',
						border: 'none',
						fontWeight: '500',
						height: '40px',
						width: '90%',
						background: '#30E700',
						fontFamily: 'Hero New',
					}}
				>
					{t('DONE')}
				</Button>
			</div>
		</Modal>
	);
};

export default TermsTNG;
