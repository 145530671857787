import '../../css/Login.css';
import { Button, Modal, Spin, message } from 'antd';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import OtpInput from 'react18-input-otp';
import Whatsapp from '../../components/Whatsapp';
import axios from 'axios';
import axiosClient from '../../utils/axiosClient';
import check from '../../images/Haleon/checkmark1.png';
import haleonLogo from '../../images/Haleon/HaleonLogo.svg';
import img1 from '../../images/Haleon/happyFamily.png';
import rejected from '../../images/Haleon/rejected.png';
import { useLocalStorage } from '@uidotdev/usehooks';
import { useTranslation } from 'react-i18next';

const inputStyle = {
	border: 'none',
	borderRadius: 0,
	width: '2.5rem',
	height: '3rem',
	textAlign: 'center',
	backgroundColor: 'transparent',
	boxShadow: 'none',
	outline: 'none',
	color: 'white',
	background: 'transparent',
	fontSize: '20px',
	fontWeight: 'bold',
	borderBottom: '2px solid #30E700',
};

const containerStyle = {
	textAlign: 'center',
	margin: 'auto',
	justifyContent: 'center',
	marginBottom: '5%',
	borderBottom: 'black',
};

const LoginPage = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [otp, setOtp] = useState('');
	const [error, setError] = useState('');
	const [msg, setMsg] = useState('');
	const [loading, setLoading] = useState(false);
	const [timer, setTimer] = useState(60);
	const { t } = useTranslation();
	const [errorModal, setErrorModal] = useState(false);
	const [, setTempOtp] = useLocalStorage('tempOtp');
	// const [, setUserToken] = useLocalStorage('HALEON-REWARDS_USER_TOKEN');

	// const storeId = location.state?.storeId;
	// const referId = location.state?.referId;
	const isSGRegion =
		window.location.hostname.replace('www.', '') ===
		process.env.REACT_APP_SG_DOMAIN;

	const getLanguage = localStorage.getItem('HALEON-REWARDS_LANG');

	useEffect(() => {
		if (timer > 0) {
			const count = setTimeout(() => {
				setTimer(timer - 1);
			}, 1000);
			return () => clearTimeout(count);
		}
	}, [timer]);

	const showModal = async () => {
		setLoading(true);

		try {
			/* const verifyOtp = {
				otp,
				phone: location.state.phone,
			}; */
			const IDENTITY = location.state?.identity;
			const res = await axiosClient.post('/auth/verify-token', {
				token: otp,
				tokenAction: IDENTITY,
				tokenType: 'PHONE',
				recipient: (isSGRegion ? '65' : '60') + location.state?.phone,
				degenerate: IDENTITY === 'REGISTER' ? false : true,
			});

			if (res) {
				setMsg(t('Your number has been successfully verified.'));
				setIsModalOpen(true);

				if (IDENTITY === 'LOGIN') {
					// set temp otp for register
					localStorage.setItem('HALEON-REWARDS_USER_TOKEN', res.data.token); // setUserToken(res.data.token);
				} else if (IDENTITY === 'REGISTER') {
					// set temp otp for register
					setTempOtp(otp);

					setTimeout(() => {
						navigate('/register', { state: location.state });
					}, 3000);
				}

				// ensure consistent dynamic local storage token,
				// ref to checkTokenAndRedirectToRelativeLogin function in AuthRedirect.js
				// localStorage.setItem('HALEON-REWARDS_USER_TOKEN', res.data.token);
			}
		} catch (error) {
			setLoading(false);
			// setErrorModal(true);
			// setError(error.response.data.error);
			setError(error.response.data.message);
			console.error(error.response.data.message);
		}
	};

	const resendOtp = async () => {
		if (timer !== 0) {
			return;
		}
		setOtp('');

		try {
			const IDENTITY = location.state?.identity;
			const res = await axiosClient.post('/auth/token', {
				recipient: isSGRegion ? '65' : '60' + location?.state.phone,
				tokenAction: IDENTITY,
				tokenType: 'PHONE',
			});

			if (res) {
				message.success('new otp code sent successfully');
				setTimer(60);
			}
		} catch (error) {
			message.error(error?.response.data.message || 'Error');
		}
	};

	const checkRegister = async () => {
		if (location.state.identity === 'REGISTER') {
			navigate('/register', { state: location.state });
		} else {
			navigate('/home');
		}
	};

	//   useEffect(() => {
	//     if (!storeId && !referId) {
	//         setErrorModal(true);
	//     }
	// }, [storeId, referId]);

	return (
		<div className="text-center">
			<div className="logo-position">
				<img src={haleonLogo} alt="haleon" style={{ width: '30%' }} />
				{/* <Whatsapp /> */}
			</div>
			<img src={img1} alt="pic" className="w-100" />
			<div className="layout-position">
				<div className="card" style={{ textAlign: 'center' }}>
					<br />
					<h1 className='font-family-hero-new font-weight-600'>{t('OTP Verification')}</h1>
					<div className="mt-1"></div>
					<hr className='green-line'/>
					<p className="mt-2 text-center font-family-hero-new font-weight-400">
						{getLanguage === 'bm' ? (
							<>
								{' '}
								Sila masukkan kod pengesahan yang dihantar ke{' '}
								<b>
									{isSGRegion ? '65' : '60'}
									{location?.state?.phone}
								</b>
							</>
						) : getLanguage === 'cn' ? (
							<>
								请输入发送到 {isSGRegion ? '65' : '60'}
								{location?.state?.phone} 的验证码
							</>
						) : (
							<>
								Please enter the verification code sent to{' '}
								<br />
								<b>
									{isSGRegion ? '65' : '60'}
									{location?.state?.phone}
								</b>
							</>
						)}{' '}
					</p>

					<div className="login-input-container font-family-hero-new">
						<OtpInput
							value={otp}
							numInputs={4}
							onChange={(e) => setOtp(e)}
							isInputNum={true}
							separator={<span> &nbsp;&nbsp;&nbsp;&nbsp; </span>}
							containerStyle={containerStyle}
							inputStyle={inputStyle}
						/>
					</div>

					{!isModalOpen ? (
						<>
							{' '}
							<div className="verify-container">
								{error ? (
									<div
										style={{
											textAlign: 'center',
											color: 'red',
											fontSize: '17px',
										}}
									>
										{error}
									</div>
								) : (
									''
								)}
								<div
									style={{
										textAlign: 'center',
										fontSize: '25px',
										color: 'white',
									}}
								>
									{timer}s
								</div>
								<br />
								<div className="verify-btn">
									<Button
										loading={loading}
										onClick={showModal}
										type="primary"
										className="font-family-hero-new" 
										style={{
											backgroundColor: '#30E700',
											textDecoration: 'none',
											width: '220px',
											color: 'black',
											fontWeight: '500',
											fontSize: '16px',
										}}
									>
										{t('Verify')}
									</Button>
								</div>
							</div>
							<div>
								<p
									className="text-center mt-1"
									onClick={resendOtp}
									style={{
										opacity: timer === 0 ? '1' : '0.5',
										fontSize: '14px',
										fontWeight: '700',
										cursor: 'pointer',
									}}
								>
									{t('Resend Code')}
								</p>
							</div>
						</>
					) : (
						<Spin className="verify-loading-btn pos-relative" />
					)}
					<div className="text-deco-none">
						<Modal
							open={isModalOpen}
							footer={null}
							closable={false}
							centered
							width={330}
						>
							<div className="text-center">
								<img src={check} alt="" style={{ width: '22%' }}></img>
								<h2 className="font-family-hero-new fontSize-25" style={{ fontWeight: '700' }}>{t('Success')}!</h2>
								<p className="text-center mt-1">{msg}</p>
								<Button
									type="primary"
									className="mt-2 text-deco-none font-family-hero-new fontSize-18"
									style={{
										color: 'black',
										width: '80%',
										background: '#30E700',
										fontWeight: '500',
										borderRadius: '5px',
									}}
									onClick={() => checkRegister()}
									// onClick = {()=> navigate("/home")}
								>
									{t('Done')}
								</Button>
							</div>
						</Modal>

						<Modal
							open={errorModal}
							centered
							footer={null}
							closable={true}
							width={300}
							onCancel={() => setErrorModal(false)}
						>
							<div style={{ display: 'flex', justifyContent: 'center' }}>
								<img src={rejected} alt="" style={{ width: '20%' }}></img>
							</div>
							<br />
							<div
								style={{
									fontWeight: 'bold',
									fontSize: '18px',
									textAlign: 'center',
								}}
							>
								{error}
							</div>
						</Modal>
					</div>
				</div>
			</div>
		</div>
	);
};

export default LoginPage;
