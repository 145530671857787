import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Button, message } from 'antd';
import HowDoesItWorkIcon from '../../images/Haleon/Badges/HowDoesItWork.svg';
import numbering from '../../images/Haleon/numbering.svg';
import referFriendImg from '../../images/Haleon/refer-a-friend.png';
import SubHeader from '../../components/SubHeader';
import '../../css/ReferAFriend.css';
import axiosClient from '../../utils/axiosClient';

const ReferAFriend = ({ t }) => {
	const navigate = useNavigate();
	const [data, setData] = useState();
	const userToken = localStorage.getItem(`HALEON-REWARDS_USER_TOKEN`);
	const getLanguage = localStorage.getItem(`HALEON-REWARDS_LANG`);
	useEffect(() => {
		if (!userToken) {
			navigate('/welcome' + window.location.search);
			return;
		}
		const checkUser = async () => {
			try {
				const res = await axiosClient.get('/users/details');
				if (res) {
					setData(res.data.data.personalInfo?.referralCode);
				}
			} catch (error) {
				// navigate(`/login?store=${store}`);
			}
		};
		checkUser();
	}, [userToken, navigate]);

	const clickReferralLink = () => {
		if (!data) {
			return message.error('No referral code found');
		}
		navigator.clipboard.writeText(
			`${window.location.origin}?referCode=${data}`
		);
		message.success('Copied to clipboard!');
	};

	return (
		<div>
			<SubHeader setPreviousPage={-1} />

			<div className="ml-3 mr-3 mt-2" style={{ paddingBottom: '50px' }}>
				<div>
					<div className="mt-3">
						<p style={{ fontSize: '25px', fontWeight: '500' }}>
							{t('Refer a friend')}
						</p>
						<p style={{ fontWeight: '400' }} className="fontSize-16 mt-1">
							{t('And you can both earn 10 points')}.
						</p>
					</div>

					<div
						className="mt-3"
						style={{
							alignItems: 'center',
							display: 'flex',
							justifyContent: 'left',
						}}
					>
						<img src={HowDoesItWorkIcon} alt="" style={{ width: '20px' }} />
						<span
							style={{
								marginLeft: '10px',
								fontSize: '18px',
								fontWeight: '500',
								fontFamily: 'Hero New'
							}}
						>
							{t('How does it work')}?
						</span>
					</div>

					<div
						className="mt-1"
						style={{ display: 'flex', justifyContent: 'left', gap: '30px' }}
					>
						<div style={{ alignItems: 'center' }}>
							<img
								src={numbering}
								alt=""
								style={{
									width: getLanguage === 'bm' ? '23px' : '20px',
									height: '200px',
									postion: getLanguage === 'bm' && 'relative',
									paddingTop: getLanguage === 'bm' && '11px',
								}}
							/>
						</div>

						<div>
							<div style={{ marginTop: '10px' }}>
								<p className="title">{t('Invite Your Friends')}</p>
								<p className="description" style={{ color: '#62798A' }}>
									{t('Share your referral link')}.
								</p>
							</div>

							<div style={{ marginTop: '25px' }}>
								<p className="title">{t('They earn 10 points')}.</p>
								{getLanguage === 'bm' ? (
									<p className="fontSize-12" style={{ color: '#62798A' }}>
										Kawan anda akan memperolehi 10 mata ganjaran dengan
										menggunakan pautan anda.
									</p>
								) : getLanguage === 'cn' ? (
									<p className="description" style={{ color: '#62798A' }}>
										您的朋友使用您的链接将获得10积分.
									</p>
								) : (
									<p className="description" style={{ color: '#62798A' }}>
										Your friend will earn 10 points for
										<br /> using your link.
									</p>
								)}
							</div>

							<div
								style={{
									marginTop: '5px',
									display: 'flex',
									justifyContent: 'center',
								}}
							>
								<div>
									<p
										className="title"
										style={{ paddingTop: getLanguage === 'cn' ? '12%' : '0' }}
									>
										{t('You earn too')}!
									</p>
									<p className="description" style={{ color: '#62798A' }}>
										{t('For each referral you get 10 points')}!
									</p>
								</div>
								<div>
									<img
										src={referFriendImg}
										alt=""
										style={{
											width: '100px',
											position: 'relative',
											left: '20px',
										}}
									/>
								</div>
							</div>
						</div>
					</div>

					<div
						className='refer-link-container'
						onClick={clickReferralLink}
					>
						<p className="ml-1 refer-link-text">
							{`${window.location.origin}?referCode=${data}`}
						</p>
						<Button className="copy-btn mr-1 font-family-hero-new" onClick={clickReferralLink}>
							{t('Copy')}
						</Button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ReferAFriend;
