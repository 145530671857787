import { Modal, Button } from 'antd';
import tng from '../../images/Haleon/tng.png';
import { useTranslation } from 'react-i18next';
import '../../css/HowToRedeem.css';

const HowToRedeemTNG = ({ redeemTNG, setRedeemTNG }) => {
	const { t } = useTranslation();
	const getLanguage = localStorage.getItem('HALEON-REWARDS_LANG');
	return (
		<Modal open={redeemTNG} centered footer={null} closable={false} width={350}>
			<div className="text-center" style={{margin: "10px 0"}}>
				<img style={{ width: '28%' }} src={tng} alt="" />
				<div className="mt-1" style={{ lineHeight: '25px' }}>
					<h3
						style={{
							fontSize: '20px',
							width: 'auto',
							fontFamily:"Hero New",
						}}
					>
						{getLanguage === 'bm' ? (
							<>Cara Menebus PIN Tambah Nilai Touch ‘n Go eWallet Anda?</>
						) : getLanguage === 'cn' ? (
							<>如何兑换您的 Touch 'n Go eWallet 充值码？</>
						) : (
							<>
								How to Redeem Your <br />
								Touch ‘n Go eWallet <br />
								Reload PIN?
							</>
						)}
					</h3>
				</div>
				<div className="mt-2">
					<ol
						style={{
							padding: '0 15px',
							fontSize: '14px',
							textAlign: 'justify',
							textJustify: 'auto',
							fontFamily:"Hero New",
							marginLeft: "10px"
						}}
					>
						<li className="my-1">
							{t('Open the Touch ‘n Go eWallet Reload PIN app.')}
						</li>
						<li className="my-1">{t('Click ‘Reload’.')}</li>
						<li className="my-1">{t('Click ‘eWallet Balance’.')}</li>
						<li className="my-1">
							{t(
								'Click on ‘Touch ‘n Go eWallet Reload PIN at the bottom of the page’.'
							)}
						</li>
						<li className="my-1">
							{t(
								'Key in the Touch ‘n Go eWallet Reload PIN and click ‘Reload Now’.'
							)}
						</li>
						<li className="my-1">
							{t('Successfully redeem Touch ‘n Go eWallet Reload PIN.')}
						</li>
					</ol>
				</div>
				<Button
					type="primary"
					className="mt-1 done-button"
					onClick={() => setRedeemTNG(false)}
				>
					{t('DONE')}
				</Button>
			</div>
		</Modal>
	);
};

export default HowToRedeemTNG;
