import '../../css/Login.css';
import { Button, Form, Input, Modal, message } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import axiosClient from '../../utils/axiosClient';
import checkInputFormat from '../../utils/misc/validation';
import rejected from '../../images/Haleon/rejected.png';
import { useEffect, useState } from 'react';
import { ca } from 'date-fns/locale';
import Whatsapp from '../Whatsapp';
import { useTranslation } from 'react-i18next';

const LoginForm = () => {
	const searchParams = new URLSearchParams(window.location.search);
	const { t } = useTranslation();
	const location = useLocation();
	const navigate = useNavigate();
	const [form] = Form.useForm();
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState('');
	const [disabled, setDisabled] = useState(false);
	const [success, setSuccess] = useState(false);
	const [exist, setExist] = useState(false);
	// const [number, setNumber] = useState('');
	const [errorModal, setErrorModal] = useState(false);
	const storeId = location.state?.storeId;
	const referId = location.state?.referId;
	const isSGRegion =
		window.location.hostname.replace('www.', '') ===
		process.env.REACT_APP_SG_DOMAIN;
	const regexPhoneNum = isSGRegion ? /^([0-9]{8})$/ : /^(1[0-9]{8,9})$/;

	const validator = async (rule, value) => {
		const { result, message } = checkInputFormat({ [rule.field]: value });
		if (!result) {
			setDisabled(true);
			return Promise.reject(new Error(message));
		} else {
			setDisabled(false);
		}
	};

	const handleSubmit = async (values) => {
		setLoading(true);

		try {
			const sendData = {
				phone: (isSGRegion ? '65' : '60') + values.phone,
			};
			const res = await axiosClient.post('/auth/check-user', sendData);

			setLoading(false);

			if (res && res.data?.identity) {
				if (res.data?.identity !== 'REGISTER') {
					// remove all the local storage
					localStorage.removeItem('HALEON-REWARDS_REFER_CODE');
					localStorage.removeItem('HALEON-REWARDS_PROMOTER_CODE');
					localStorage.removeItem('HALEON-REWARDS_PROMOTER_ID');
					localStorage.removeItem('HALEON-REWARDS_CHANNEL');
					localStorage.removeItem('HALEON-REWARDS_STORE_CODE');
					localStorage.removeItem('HALEON-REWARDS_STORE_ID');
				}
				const data = {
					...values,
					identity: res.data?.identity,
					// storeId,
				};
				// console.info("res", res);
				navigate(`/verify-otp${window.location.search}`, { state: data });
			}
		} catch (error) {
			console.log('error', error);
			setErrorModal(true);
			setLoading(false);
			setExist(true);
			setError(error.response.data.error);
		}
	};

	return (
		<div>
			<div>
				<Form
					form={form}
					name="login-form"
					id="login-form"
					onFinish={handleSubmit}
					disabled={loading}
					aria-label="form"
					data-testid="login-form"
					autoComplete="off"
					style={{ textAlign: 'center', marginTop: '10%', color: 'white' }}
				>
					<div
						style={{
							display: 'flex',
							justifyContent: 'left',
							background: 'transparent',
						}}
					>
						<div
							style={{
								borderRadius: '10px',
								border: '2px solid #DEDEDE',
								background: 'transparent',
								width: '16%',
								height: '40px',
								fontSize: '12px',
								color: 'white',
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								marginLeft: '10%',
							}}
						>
							<span>+{isSGRegion ? '65' : '60'}</span>
						</div>

						<Form.Item
							name="phone"
							validateDebounce={500}
							hasFeedback
							className="login-text-color"
							style={{ fontSize: '12px', width: '110%', textAlign: 'left' }}
							rules={[
								{
									required: true,
									message: 'Phone is required',
								},
								({ getFieldValue }) => ({
									validator(_, value) {
										if (value && value?.length > 0) {
											if (regexPhoneNum.test(value)) {
												return Promise.resolve(true);
											}
											return Promise.reject(
												new Error('Invalid phone number format!')
											);
										}
										return Promise.resolve(true);
									},
								}),
							]}
						>
							<Input
								type="tel"
								name="phone"
								style={{
									borderRadius: '10px',
									border: '2px solid #DEDEDE',
									background: 'transparent',
									width: '80%',
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
									margin: 'auto',
									height: '40px',
									position: 'relative',
									right: '3%',
									color: 'white',
								}}
								className="input-placeholder"
								placeholder="Your phone number"
							/>
						</Form.Item>
					</div>
					<Form.Item>
						<Button
							type="primary"
							htmlType="submit"
							className="font-family-hero-new" 
							style={{
								width: '80%',
								backgroundColor: '#30E700',
								color: 'black',
								border: 'none',
								height: '40px',
								fontSize: '16px',
								fontWeight: '500px'
							}}
						>
							{t('Send OTP Code')}
						</Button>
					</Form.Item>
				</Form>
			</div>

			<div>
				<Modal
					open={success}
					centered
					header={null}
					footer={null}
					closable={true}
				>
					<div className="submit-login-success-modal-container">
						<img
							alt=""
							className="submit-success-icon"
							style={{ width: '15vw' }}
						></img>
						<div className="submit-login-modal-header">Success!</div>
						<div className="submit-login-success-modal-font">
							New one-time PIN sent. Please check your phone for the code.
						</div>
						<Button onClick={() => navigate('/verify-otp')}>DONE</Button>
					</div>
				</Modal>
			</div>

			<div>
				<Modal
					open={errorModal}
					centered
					footer={null}
					width={300}
					closable={true}
					onCancel={() => setErrorModal(false)}
				>
					<div style={{ display: 'flex', justifyContent: 'center' }}>
						<img src={rejected} alt="" style={{ width: '20%' }}></img>
					</div>
					<br />
					<div
						style={{
							fontWeight: 'bold',
							fontSize: '20px',
							textAlign: 'center',
						}}
					>
						Unable to send OTP
						<p className="text-center">please try again</p>
					</div>
				</Modal>
			</div>
		</div>
	);
};

export default LoginForm;
