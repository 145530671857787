import { Modal, Button } from 'antd';
import spay from '../../images/Haleon/spay.png';
import { useTranslation } from 'react-i18next';
import '../../css/HowToRedeem.css';

const HowToRedeemSpay = ({ redeemSpay, setRedeemSpay }) => {
	const { t } = useTranslation();
	return (
		<Modal
			open={redeemSpay}
			centered
			footer={null}
			closable={false}
			width={365}
		>
			<div className="text-center" style={{ margin: "10px 0" }}>
				<img style={{ width: '28%' }} src={spay} alt="" />
				<div className="mt-1" style={{ lineHeight: '25px' }}>
					<h3
						style={{
							fontSize: '20px',
							width: 'auto',
							fontFamily:"Hero New",
						}}
					>
						{t('How to Redeem Your SPAY Voucher?')}
					</h3>
				</div>
				<div className="mt-2">
					<ol
						style={{
							padding: '0 15px',
							fontSize: '14px',
							textAlign: 'justify',
							textJustify: 'auto',
							fontFamily:"Hero New",
							marginLeft: "10px"
						}}
					>
						<li className="ml-1">{t('Click on Loyalty Rewards Icon')}</li>
						<li className="ml-1">{t('Click on Redeem')}</li>
						<li className="ml-1">{t('Key in the redemption code')}</li>
						<li className="ml-1">{t('Key in the verification code')}</li>
						<li className="ml-1">{t('Click on Get Voucher Now')}</li>
						<li className="ml-1">
							{t(
								'Upon successful redemption, the voucher with its value will appear'
							)}
						</li>
					</ol>
				</div>
				<Button
					type="primary"
					className="mt-2 done-button"
					onClick={() => setRedeemSpay(false)}
				>
					{t('DONE')}
				</Button>
			</div>
		</Modal>
	);
};

export default HowToRedeemSpay;
