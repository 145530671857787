import React, { useEffect, useState } from 'react';
import SubHeader from '../../components/SubHeader';
import { Switch, message } from 'antd';
import { useNavigate } from 'react-router-dom';
import axiosClient from '../../utils/axiosClient';
import CancelSubscription from '../../components/Modal/CancelSubscription';

const ContactPreference = ({t}) => {
	const navigate = useNavigate();
	const userToken = localStorage.getItem(`HALEON-REWARDS_USER_TOKEN`);
	const [loading, setLoading] = useState(true);
	const [email, setEmail] = useState(true);
	const [phone, setPhone] = useState(true);
	const [sms_Wa, setSms_Wa] = useState(true);
	const [isSuccess, setIsSuccess] = useState(false);
	const [handleUnsubscribeType, setHandleUnsubscribeType] = useState('');

	useEffect(() => {
		if (!userToken) {
			navigate('/welcome' + window.location.search);
		}
	}, [userToken, navigate]);

	useEffect(() => {
		if (!userToken) return;
		const fetchData = async () => {
			try {
				const res = await axiosClient.get('/users/details');
				const getInfo = res.data.data.personalInfo.configNoti?.general;
				setLoading(false);

				if (!getInfo) {
					setEmail(true);
					setPhone(true);
					setSms_Wa(true);
					await axiosClient.post('/profile/update', {
						configNoti: {
							general: {
								email: true,
								phone: true,
								sms_Wa: true,
							},
						},
					});
				} else {
					setEmail(getInfo?.email ?? email);
					setPhone(getInfo?.phone ?? phone);
					setSms_Wa(getInfo?.sms_Wa ?? sms_Wa);
				}
			} catch (error) {
				console.error('Error fetching user details:', error);
			}
		};
		fetchData();
	}, [userToken]);

	useEffect(() => {}, [email, phone, sms_Wa]);

	const handleSubscribe = (value, type) => {
		axiosClient.post('/profile/update', {
			configNoti: {
				general: {
					email: type === 'EMAIL' ? value : email,
					phone: type === 'PHONE' ? value : phone,
					sms_Wa: type === 'SMS_WA' ? value : sms_Wa,
				},
			},
		});
		message.success('Subscribe!');
	};

	return (
		<div style={{ fontFamily: 'Hero New, Arial' }}>
			<SubHeader setPreviousPage={-1} />
			<div className="mt-1 ml-2 mr-2" style={{ paddingBottom: '10%' }}>
				<div className="mt-4">
					<h2 className="fontSize-25 font-weight-500">{t('Contact Preferences')}</h2>
				</div>
				{loading ? (
					<div style={{ textAlign: 'center', paddingTop: '15%' }}>
						<p style={{ textAlign: 'center' }}>Loading...</p>
					</div>
				) : (
					<div>
						<div
							className="mt-2"
							style={{ display: 'flex', justifyContent: 'space-between' }}
						>
							<p className="t-black fontSize-18 font-weight-300">{t('Email Newsletter')}</p>
							<Switch
								checked={email}
								onChange={() => {
									let type = 'EMAIL';
									if (!email) {
										setEmail(true);
										handleSubscribe(!email, type);
									} else {
										setIsSuccess(true);
										setHandleUnsubscribeType(type);
									}
								}}
								className="contact-checkbox"
							/>
						</div>
						<hr style={{ width: '100%', marginTop: '5%' }} />
						<div
							className="mt-2"
							style={{ display: 'flex', justifyContent: 'space-between' }}
						>
							<p className="t-black fontSize-18 font-weight-300">{t('Phone Call')}</p>
							<Switch
								checked={phone}
								// onChange={handlePhoneChange}
								onChange={() => {
									let type = 'PHONE';
									if (!phone) {
										setPhone(true);
										handleSubscribe(!phone, type);
									} else {
										setIsSuccess(true);
										setHandleUnsubscribeType(type);
									}
								}}
								className="contact-checkbox"
							/>
						</div>
						<hr style={{ width: '100%', marginTop: '5%' }} />
						<div
							className="mt-2"
							style={{ display: 'flex', justifyContent: 'space-between' }}
						>
							<p className="t-black fontSize-18 font-weight-300">{t('SMS / WhatsApp')}</p>
							<Switch
								checked={sms_Wa}
								onChange={() => {
									let type = 'SMS_WA';
									if (!sms_Wa) {
										setSms_Wa(true);
										handleSubscribe(!sms_Wa, type);
									} else {
										setIsSuccess(true);
										setHandleUnsubscribeType(type);
									}
								}}
								className="contact-checkbox"
							/>
						</div>
					</div>
				)}
				<CancelSubscription
					setIsSuccess={setIsSuccess}
					isSuccess={isSuccess}
					sms_Wa={sms_Wa}
					phone={phone}
					email={email}
					setHandleUnsubscribeType={setHandleUnsubscribeType}
					handleUnsubscribeType={handleUnsubscribeType}
				/>
			</div>
		</div>
	);
};

export default ContactPreference;
