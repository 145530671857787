import React, { useState, useEffect } from 'react';
import { Button, Modal, message } from 'antd';
import axiosClient from '../../utils/axiosClient'; 

const BadgesRedeemReward = ({
	selectedMission,
	redeem,
	setRedeem,
	handleSuccess,
}) => {
	const isSGRegion =
		window.location.hostname.replace('www.', '') ===
		process.env.REACT_APP_SG_DOMAIN;
	const [selectedReward, setSelectedReward] = useState();
	const [isLoading, setIsLoading] = useState(false);
	useEffect(() => {
		if (selectedMission?.rewards?.length === 1) {
			setSelectedReward(selectedMission.rewards[0]);
		}
	}, [selectedMission]);

	const handleConfirmReward = async () => {
		if (!selectedMission) {
			message.error('No mission selected');
			return;
		}

		if (!selectedReward) {
			message.error('Please select a reward');
			return;
		}

		if (selectedReward?.message === 'Out Of Stock') {
			message.error('Out Of Stock');
			return;
		}

		if (
			!selectedMission.campaign ||
			!selectedMission.campaign.id ||
			!selectedMission.campaign.type
		) {
			message.error('Missing campaign information');
			return;
		}

		setIsLoading(true);

		try {
			let entryData = {
				campaignId: selectedMission.campaign.id,
				type: selectedMission.campaign.type,
				selectedRewardIds: selectedReward ? [selectedReward.id] : [],
				data: {
					campaignInput: {
						validationData: selectedMission?.validationData,
					},
				},
			};

			const checkCampaignIdForTheClickedMission = await axiosClient.post(
				`campaigns/entries`,
				entryData,
				{
					headers: { 'accept-version': '~2' },
				}
			);

			if (checkCampaignIdForTheClickedMission?.data) {
				handleSuccess();
				window.location.reload();
			} else {
				message.error(
					'Invalid error' ||
						checkCampaignIdForTheClickedMission?.response?.data?.message
				);
			}
		} catch (err) {
			if (err.response && err.response.data && err.response.data.message) {
				message.error(err.response.data.message);
			} else {
				message.error('An error occurred');
			}
			console.log('error', err);
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {}, [selectedReward]);

	return (
		<div style={{ fontFamily: 'Hero New, Arial' }}>
			<Modal
				open={redeem}
				centered
				header={null}
				footer={null}
				closable={false}
				width={330}
			>
				<div className="text-center mt-1">
					<div
						style={{
							width: '100px',
							height: '100px',
							margin: 'auto',
							border: isSGRegion ? 'none' : '3px solid #30E700',
							overflow: 'hidden',
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
						}}
					>
						<img
							style={{
								width: isSGRegion ? '6rem' : '70%',
								height: isSGRegion ? '6rem' : '70%',
								objectFit: 'contain',
							}}
							src={selectedMission?.campaign?.imageUrl}
							alt=""
							className="submit-success-icon"
						/>
					</div>
					<h2 style={{ paddingTop: isSGRegion ? '5%' : '0%', fontSize: '22px', fontFamily:'Hero New' }}>Claim</h2>
					<div className="submit-pending-modal-font mt-1">
						<p className="fontSize-14 text-center font-weight-400">
							{selectedMission?.rewards?.length > 1
								? `Select your eWallet Voucher now!`
								: 'Are you sure you want to redeem your reward?'}
						</p>
					</div>
					{isSGRegion ? (
						<>
							{selectedMission?.rewards?.length === 1 && (
								<div
									style={{
										display: 'flex',
										justifyContent: 'space-evenly',
										paddingTop: isSGRegion ? '0%' : '5%',
										paddingBottom: isSGRegion ? '0%' : '5%',
									}}
								>
									{selectedMission.rewards.map((image, index) => {
										return (
											<div
												key={index}
												onClick={() => {
													if (!image.message && image.qtyRemaining > 0) {
														setSelectedReward(image);
													}
												}}
												style={{
													opacity: selectedReward?.id === image?.id ? 1 : 0.5,
												}}
											>
												<img
													src={selectedMission.rewards[0].imageUrl}
													alt={`Reward ${index + 1}`}
													style={{
														width: '200px',
														height: '100px',
														objectFit: 'contain',
													}}
												/>
												{image.message === 'Out of stock!' ||
												image.qtyRemaining <= 0 ? (
													<p
														style={{
															fontSize: '12px',
															color: 'black',
															fontWeight: '500',
														}}
													>
														Out of Stock!
													</p>
												) : null}
											</div>
										);
									})}
								</div>
							)}
						</>
					) : (
						<>
							{selectedMission?.rewards?.length > 1 && (
								<div
									style={{
										display: 'flex',
										justifyContent: 'space-evenly',
										paddingTop: '5%',
										paddingBottom: '5%',
									}}
								>
									{selectedMission.rewards.map((image, index) => {
										return (
											<div
												key={index}
												onClick={() => {
												
													if (!image.message && image.qtyRemaining > 0) {
														setSelectedReward(image);
													}
												}}
												style={{
													opacity: selectedReward?.id === image?.id ? 1.0 : 0.5,
												}}
											>
												<img
													src={image.imageUrl}
													alt={`Reward ${index}`}
													style={{
														width: '70px',
														height: '70px',
														objectFit: 'contain',
													}}
												/>
												{image.message === 'Out of stock!' ||
												image.qtyRemaining <= 0 ? (
													<p
														style={{
															fontSize: '12px',
															color: 'black',
															fontWeight: '500',
														}}
													>
														Out of Stock!
													</p>
												) : null}
											</div>
										);
									})}
								</div>
							)}
						</>
					)}

					<Button
						disabled={isLoading}
						className="coming-soon-button mt-1"
						onClick={() => {
							handleConfirmReward();
						}}
						style={{ fontWeight: '500', fontSize: '16px', fontFamily:'Hero New', }}
					>
						Confirm
					</Button>
					<Button
						className="mt-1"
						onClick={() => {
							setSelectedReward();
							setRedeem(false);
						}}
						style={{
							width: '90%',
							background: 'transparent',
							color: 'black',
							borderRadius: '5px',
							height: '35px',
							border: '1px solid black',
							fontWeight: 'medium',
							fontSize: '16px', 
							fontFamily:'Hero New',
							fontWeight: '500'
						}}
					>
						Later
					</Button>
				</div>
			</Modal>
		</div>
	);
};
export default BadgesRedeemReward;
