import React, { useState, useEffect } from 'react';
import { Button, Progress } from 'antd';
// import { useDispatch, useSelector } from 'react-redux';
// import { checkUserSession, fetchTierHistory } from '../../reducer/userReducer';
import '../../css/Tiering.css';
import icon from '../../images/Haleon/user.png';
import silverMedal from '../../images/Haleon/silverMedal1.png';
import bronzeMedal from '../../images/Haleon/bronzeMedal.png';
import platinumMedal from '../../images/Haleon/platinum.png';
import goldMedal from '../../images/Haleon/goldMedal1.png';
import welcome from '../../images/Haleon/Tiering/welcome.svg';
import img2 from '../../images/Haleon/Tiering/img2.svg';
import tierUpgrade from '../../images/Haleon/Tiering/tierUpgrade.png';
import tierDowngrade from '../../images/Haleon/Tiering/tierDowngrade.png';
import UserFooter from '../../components/UserFooter';
import MainHeader from '../../components/MainHeader';
import moment from 'moment';
import axiosClient from '../../utils/axiosClient';

const ProfilePage = ({ t }) => {
	// const {
	// 	userData,
	// 	point,
	// 	tierHistory,
	// 	currentPoints,
	// 	expired,
	// 	requirePoint,
	// 	nextTier,
	// } = useSelector((state) => state.user);
	const [tiering, setTiering] = useState('Bronze');
	const [type, setType] = useState('Tiering');
	const localtoken = localStorage.getItem('HALEON-REWARDS_USER_TOKEN');
	// const dispatch = useDispatch();
	const [userData, setUserData] = useState();
	const [points, setPoints] = useState();
	const [tierHistory, setTierHistory] = useState();
	const isSGRegion =
		window.location.hostname.replace('www.', '') ===
		process.env.REACT_APP_SG_DOMAIN;
	const getLanguage = localStorage.getItem('HALEON-REWARDS_LANG');

	useEffect(() => {
		const check = async () => {
			try {
				const res = await axiosClient.get('/users/details');
				setUserData(res.data.data.personalInfo);
			} catch (error) {
				// navigate(`/login?store=${store}`);
			}
		};

		const tierHistory = async () => {
			const checkTierHistory = await axiosClient.get('/tier-histories');
			if (checkTierHistory && checkTierHistory.data.data.length > 0) {
				setTierHistory(checkTierHistory.data.data);
			}
		};
		check();
		tierHistory();
	}, []);

	const formatTierChangeDate = (date) => {
		return new Date(date).toLocaleDateString('en-GB');
	};

	return (
		<div>
			<div>
				<MainHeader />
			</div>
			<div className="mt-3 ml-2 mr-2">
				<div className="user-container">
					<div style={{ display: 'flex', justifyContent: 'space-between' }}>
						<div className="ml-2 mt-2">
							<h2 style={{ fontSize: '25px', fontFamily: 'Hero New'}}>{userData?.name}</h2>
							<img
								src={icon}
								alt=""
								style={{ width: 'auto', objectFit: 'contain' }}
							/>
							<span className="ml-1" style={{ fontSize: '16px', fontFamily: 'Hero New'}}>
								****{userData?.phone?.slice(3, 7) || '***'}
							</span>
						</div>
						<div className="mr-2 mt-2">
							<h3
								style={{
									fontWeight: '400',
									fontSize: getLanguage === 'bm' ? '15px' : '20px',
									fontFamily: 'Hero New'
								}}
								className="text-center"
							>
								{t('Points')}
							</h3>
							<h1 className='total-unit-balance'>
								{userData?.totalUnitsBalance || 0}
							</h1>
						</div>
					</div>

					<div style={{ display: 'flex', justifyContent: 'space-between' }}>
						<div
							className="ml-2 mt-2"
							style={{
								width: '30%',
								alignItems: 'center',
								display: 'flex',
								justifyContent: 'space-between',
								gap: '5px',
							}}
						>
							<div>
								<img
									src={
										userData?.tier?.name === 'Bronze'
											? bronzeMedal
											: userData?.tier?.name === 'Silver'
											? silverMedal
											: userData?.tier?.name === 'Gold'
											? goldMedal
											: userData?.tier?.name === 'Platinum'
											? platinumMedal
											: bronzeMedal
									}
									alt=""
									style={{ width: '16%' }}
								/>
								{isSGRegion ? (
									<span
										className=""
										style={{ fontWeight: '800', fontSize: '16px' }}
									>
										{' '}
										{userData?.tier?.name || 'Bronze'}
									</span>
								) : (
									<span
										className=""
										style={{ fontWeight: '700', fontSize: '16px', fontFamily: 'Hero New'}}
									>
										{' '}
										{userData?.tier?.name === 'Platinum'
											? t('userPlatinum')
											: userData?.tier?.name === 'Gold'
											? t('userGold')
											: userData?.tier?.name === 'Silver'
											? t('userSilver')
											: t('userBronze') || t('userBronze')}
									</span>
								)}
							</div>
						</div>
						<div className="" style={{ width: '70%' }}>
							<div style={{ position: 'relative', right: '20px' }}>
								<Progress
									percent={
										userData?.tier?.name === 'Bronze'
											? (userData?.totalUnitsEarnedLastCycle / 250) * 100
											: userData?.tier?.name === 'Silver'
											? (userData?.totalUnitsEarnedLastCycle / 600) * 100
											: userData?.tier?.name === 'Gold'
											? (userData?.totalUnitsEarnedLastCycle / 1850) * 100
											: userData?.tier?.name === 'Platinum'
											? (userData?.totalUnitsEarnedLastCycle / 1) * 100
											: 0 / 250
									}
									strokeColor={'#30E700'}
									showInfo={false}
								/>
							</div>
							<div
								style={{
									display: 'flex',
									justifyContent: 'right',
									marginRight: '30px',
								}}
							>
								<p style={{ fontWeight: '600' }} className="fontSize-16">
									{userData?.tier?.name === 'Bronze'
										? `${userData?.totalUnitsEarnedLastCycle || 0}/250`
										: userData?.tier?.name === 'Silver'
										? `${userData?.totalUnitsEarnedLastCycle || 0}/600`
										: userData?.tier?.name === 'Gold'
										? `${userData?.totalUnitsEarnedLastCycle || 0}/1000`
										: userData?.tier?.name === 'Platinum'
										? userData?.totalUnitsEarnedLastCycle
										: `0/250`}
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="ml-2 mr-2" style={{ paddingBottom: '100px' }}>
				{type === 'Tiering' ? (
					<div
						style={{ justifyContent: 'space-between' }}
						className="d-flex mt-2"
					>
						<p
							className="tiering-benefits-font"
							onClick={() => setType('Tiering')}
						>
							{t('Tiering Benefits')}
						</p>
						<p className="view-history-font" onClick={() => setType('History')}>
							{t('View History')}
						</p>
					</div>
				) : (
					<div
						style={{ justifyContent: 'space-between' }}
						className="d-flex mt-2"
					>
						<p
							className="tiering-benefits-font"
							onClick={() => setType('History')}
						>
							{t('Tiering History')}
						</p>
						<p className="view-history-font" onClick={() => setType('Tiering')}>
							{t('View Benefits')}
						</p>
					</div>
				)}

				{type === 'Tiering' ? (
					<div>
						<div
							className="mt-2 scroll-tiering"
							style={{ justifyContent: 'space-between', gap: '16px' }}
						>
							<div onClick={() => setTiering('Bronze')}>
								<Button
									className="medal-btn"
									style={{
										backgroundColor:
											tiering === 'Bronze' ? '#30E700' : '#C2C2C2',
										color: tiering === 'Bronze' ? '#000000' : '#FFFFFF',
									}}
								>
									<img
										alt="bronze"
										src={bronzeMedal}
										style={{ width: '24px', height: '24px' }}
									/>
									<span
										style={{ marginLeft: '3px' }}
										className="font-weight-700"
									>
										{t('Bronze')}
									</span>
								</Button>
							</div>

							<div onClick={() => setTiering('Silver')}>
								<Button
									className="medal-btn"
									style={{
										backgroundColor:
											tiering === 'Silver' ? '#30E700' : '#C2C2C2',
										color: tiering === 'Silver' ? '#000000' : '#FFFFFF',
									}}
								>
									<img
										alt="silver"
										src={silverMedal}
										style={{ width: '24px', height: '24px' }}
									/>
									<span
										style={{ marginLeft: '3px' }}
										className="font-weight-700"
									>
										{t('Silver')}
									</span>
								</Button>
							</div>

							<div onClick={() => setTiering('Gold')}>
								<Button
									className="medal-btn"
									style={{
										backgroundColor: tiering === 'Gold' ? '#30E700' : '#C2C2C2',
										color: tiering === 'Gold' ? '#000000' : '#FFFFFF',
									}}
								>
									<img
										alt="gold"
										src={goldMedal}
										style={{ width: '24px', height: '24px' }}
									/>
									<span
										style={{ marginLeft: '3px' }}
										className="font-weight-700"
									>
										{t('Gold')}
									</span>
								</Button>
							</div>

							<div onClick={() => setTiering('Platinum')}>
								<Button
									className="medal-btn"
									style={{
										backgroundColor:
											tiering === 'Platinum' ? '#30E700' : '#C2C2C2',
										color: tiering === 'Platinum' ? '#000000' : '#FFFFFF',
									}}
								>
									<img
										alt="platinum"
										src={platinumMedal}
										style={{ width: '24px', height: '24px' }}
									/>
									<span
										style={{ marginLeft: '3px' }}
										className="font-weight-700"
									>
										{t('Platinum')}
									</span>
								</Button>
							</div>
						</div>

						{tiering === 'Bronze' ? (
							<div>
								<div className="tiering-point-card mt-2 ml-2">
									<div
										style={{ display: 'flex', justifyContent: 'space-evenly' }}
									>
										<div className="ml-2 mt-2">
											<div id="medal-radius">
												<img
													src={bronzeMedal}
													alt="bronmemberMedalzeMedal"
													className="medal-position"
													style={{ width: '26px', height: '26px' }} 
												/>
											</div>
										</div>

										<div className="mr-2 mt-2" style={{ alignItems: 'center' }}>
											<p
												className="tiering-points-font"
												style={{
													fontSize: getLanguage === 'bm' ? '15px' : '18px',
													lineHeight: getLanguage === 'bm' && '15px',
													fontFamily: 'Hero New'
												}}
											>
												{t('Tiering Points Required')}:
											</p>
											<p className="points-font">0 - 249 Points</p>
										</div>
									</div>
								</div>
							</div>
						) : null}

						{tiering === 'Silver' ? (
							<div className="">
								<div className="tiering-point-card mt-2 ml-2">
									<div
										style={{ display: 'flex', justifyContent: 'space-evenly' }}
									>
										<div className="ml-2 mt-2">
											<div id="medal-radius">
												<img
													src={silverMedal}
													alt="silverMedal"
													className="medal-position"
													style={{ width: '26px', height: '26px' }} 
												/>
											</div>
										</div>

										<div className="mr-2 mt-2" style={{ alignItems: 'center' }}>
											<p
												className="tiering-points-font"
												style={{
													fontSize: getLanguage === 'bm' ? '15px' : '17px',
													lineHeight: getLanguage === 'bm' && '15px',
												}}
											>
												{t('Tiering Points Required')}:
											</p>
											<p className="points-font">250 - 599 Points</p>
										</div>
									</div>
								</div>
							</div>
						) : null}

						{tiering === 'Gold' ? (
							<div className="">
								<div className="tiering-point-card mt-2 ml-2">
									<div
										style={{ display: 'flex', justifyContent: 'space-evenly' }}
									>
										<div className="ml-2 mt-2">
											<div id="medal-radius">
												<img
													src={goldMedal}
													alt="goldMedal"
													className="medal-position"
													style={{ width: '26px', height: '26px' }} 
												/>
											</div>
										</div>

										<div className="mr-2 mt-2" style={{ alignItems: 'center' }}>
											<p
												className="tiering-points-font"
												style={{
													fontSize: getLanguage === 'bm' ? '15px' : '17px',
													lineHeight: getLanguage === 'bm' && '15px',
												}}
											>
												{t('Tiering Points Required')}:
											</p>
											<p className="points-font">600 - 999 Points</p>
										</div>
									</div>
								</div>
							</div>
						) : null}

						{tiering === 'Platinum' ? (
							<div className="">
								<div className="tiering-point-card mt-2 ml-2">
									<div
										style={{ display: 'flex', justifyContent: 'space-evenly' }}
									>
										<div className="ml-2 mt-2">
											<div id="medal-radius">
												<img
													src={platinumMedal}
													alt="platinumMedal"
													className="medal-position"
													style={{ width: '26px', height: '26px' }} 
												/>
											</div>
										</div>

										<div className="mr-2 mt-2" style={{ alignItems: 'center' }}>
											<p
												className="tiering-points-font"
												style={{
													fontSize: getLanguage === 'bm' ? '15px' : '17px',
													lineHeight: getLanguage === 'bm' && '15px',
												}}
											>
												{t('Tiering Points Required')}:
											</p>
											<p className="points-font">1000+ Points</p>
										</div>
									</div>
								</div>
							</div>
						) : null}

						<div>
							<div className="privileges-card mt-2 ml-2">
								<div>
									<p className="privileges-font ml-1">{t('Privileges')}</p>
								</div>

								<div className="mt-2 privileges-container text-center font-family-hero-new">
									<div>
										<div>
											<img style={{ width: '60px', height: '60px', paddingBottom: '10px'}} src={welcome} alt="welcome_icon" />
										</div>
										{tiering === 'Bronze' && (
											<>
												{getLanguage === 'bm' ? (
													<h3 style={{ position: 'relative', bottom: '10px', fontSize:'20px' }}>
														10 Bonus
														<br /> Selamat Datang{' '}
													</h3>
												) : getLanguage === 'cn' ? (
													<h3 style={{ position: 'relative', bottom: '10px', fontSize:'20px' }}>10 迎新奖励</h3>
												) : (
													<h3 style={{ position: 'relative', bottom: '10px', fontSize:'20px' }}>
														10 Welcome
														<br /> Bonus
													</h3>
												)}
											</>
										)}
										{tiering === 'Silver' && (
											<>
												{getLanguage === 'bm' ? (
													<h3 style={{ position: 'relative', bottom: '10px', fontSize:'20px' }}>
														15 Bonus
														<br /> Selamat Datang{' '}
													</h3>
												) : getLanguage === 'cn' ? (
													<h3 style={{ position: 'relative', bottom: '10px', fontSize:'20px' }}>15 迎新奖励</h3>
												) : (
													<h3 style={{ position: 'relative', bottom: '10px', fontSize:'20px' }}>
														15 Welcome
														<br /> Bonus
													</h3>
												)}
											</>
										)}
										{tiering === 'Gold' && (
											<>
												{getLanguage === 'bm' ? (
													<h3 style={{ position: 'relative', bottom: '10px', fontSize:'20px' }}>
														20 Bonus
														<br /> Selamat Datang{' '}
													</h3>
												) : getLanguage === 'cn' ? (
													<h3 style={{ position: 'relative', bottom: '10px', fontSize:'20px' }}>20 迎新奖励</h3>
												) : (
													<h3 style={{ position: 'relative', bottom: '10px', fontSize:'20px' }}>
														20 Welcome
														<br /> Bonus
													</h3>
												)}
											</>
										)}
										{tiering === 'Platinum' && (
											<>
												{getLanguage === 'bm' ? (
													<h3 style={{ position: 'relative', bottom: '10px', fontSize:'20px' }}>
														25 Bonus
														<br /> Selamat Datang{' '}
													</h3>
												) : getLanguage === 'cn' ? (
													<h3 style={{ position: 'relative', bottom: '10px', fontSize:'20px' }}>25 迎新奖励</h3>
												) : (
													<h3  style={{ position: 'relative', bottom: '10px', fontSize:'20px' }}>
														25 Welcome
														<br /> Bonus
													</h3>
												)}
											</>
										)}
									</div>

									<div>
										<img
											src={img2}
											alt="welcome_icon"
											style={{ width: '61px', height: '61px', paddingBottom: '10px' }}
										/>
										{tiering === 'Bronze' && (
											<>
												{getLanguage === 'bm' ? (
													<h3 style={{ position: 'relative', bottom: '10px', fontSize:'20px' }}>
														1x Gandaan
														<br /> Mata{' '}
													</h3>
												) : getLanguage === 'cn' ? (
													<h4  style={{ position: 'relative', bottom: '10px', fontSize:'20px' }}>1x 加倍积分</h4>
												) : (
													<h3  style={{ position: 'relative', bottom: '10px', fontSize:'20px' }}>
														1x Points
														<br />
														Multiplier
													</h3>
												)}
											</>
										)}
										{tiering === 'Silver' && (
											<>
												{getLanguage === 'bm' ? (
													<h3  style={{ position: 'relative', bottom: '10px', fontSize:'20px' }}>
														1.5x Gandaan
														<br /> Mata{' '}
													</h3>
												) : getLanguage === 'cn' ? (
													<h4  style={{ position: 'relative', bottom: '10px', fontSize:'20px' }}>
														1.5x 加倍积分
													</h4>
												) : (
													<h3  style={{ position: 'relative', bottom: '10px', fontSize:'20px' }}>
														1.5x Points
														<br />
														Multiplier
													</h3>
												)}
											</>
										)}
										{tiering === 'Gold' && (
											<>
												{getLanguage === 'bm' ? (
													<h3  style={{ position: 'relative', bottom: '10px', fontSize:'20px' }}>
														2x Gandaan
														<br /> Mata{' '}
													</h3>
												) : getLanguage === 'cn' ? (
													<h4  style={{ position: 'relative', bottom: '10px', fontSize:'20px' }}>
														2x 加倍积分
													</h4>
												) : (
													<h3  style={{ position: 'relative', bottom: '10px', fontSize:'20px' }}>
														2x Points
														<br />
														Multiplier
													</h3>
												)}
											</>
										)}
										{tiering === 'Platinum' && (
											<>
												{getLanguage === 'bm' ? (
													<h3  style={{ position: 'relative', bottom: '10px', fontSize:'20px' }}>
														3x Gandaan
														<br /> Mata{' '}
													</h3>
												) : getLanguage === 'cn' ? (
													<h4  style={{ position: 'relative', bottom: '10px', fontSize:'20px' }}>
														3x 加倍积分
													</h4>
												) : (
													<h3  style={{ position: 'relative', bottom: '10px', fontSize:'20px' }}>
														3x Points
														<br />
														Multiplier
													</h3>
												)}
											</>
										)}
									</div>
								</div>

								<div
									style={{ marginTop: getLanguage === 'cn' ? '20px' : '10px' }}
								>
									{getLanguage === 'bm' ? (
										<p
											className="text-center fontSize-12"
											style={{ lineHeight: '15px' }}
										>
											Bonus Selamat Datang: Dapatkan ganjaran mata satu kali
											apabila pengguna mencapai peringkat keahlian
										</p>
									) : getLanguage === 'cn' ? (
										<p
											className="text-center fontSize-12"
											style={{ lineHeight: '15px' }}
										>
											迎新奖励: 当用户达到该等级时，即可获
											<br />
											得一次性积分奖励
										</p>
									) : (
										<p
											className="text-center fontSize-12"
											style={{ lineHeight: '15px' }}
										>
											Welcome Bonus: Get one-time point reward when user <br />
											reach the tier
										</p>
									)}
								</div>
								<div style={{ marginTop: '10px' }}>
									{getLanguage === 'bm' ? (
										<p
											className="text-center fontSize-12"
											style={{ lineHeight: '15px' }}
										>
											Gandaan Mata: Dapatkan kesan gandaan pada mata yang
											diperoleh untuk setiap penyerahan yang sah
										</p>
									) : getLanguage === 'cn' ? (
										<p
											className="text-center fontSize-12"
											style={{ lineHeight: '15px' }}
										>
											积分加倍: 在每次有效提交中获得积分的倍增效果
										</p>
									) : (
										<p
											className="text-center fontSize-12"
											style={{ lineHeight: '15px' }}
										>
											Point Multiplier: Get multiplier effect on the points earn{' '}
											<br /> on every valid submission
										</p>
									)}
								</div>
							</div>
						</div>
					</div>
				) : (
					<div>
						<div className="mt-2">
							<div>
								<p
									className="fontSize-20 text-center"
									style={{ fontWeight: '700' }}
								>
									Membership Tiers History
								</p>
							</div>

							{tierHistory && tierHistory.length ? (
								<div>
									{tierHistory.map((tierInfo, idx) => {
										if (
											tierInfo.tierId === 'e2ea45ad-3eae-4766-90b6-41e901a956a0'
										) {
											return;
										}
										return (
											<div
												style={{
													justifyContent: 'space-evenly',
													display: 'flex',
												}}
												key={idx}
												className="tier-history-container mt-2"
											>
												<div>
													<img
														src={
															tierInfo.action === 'UPGRADE'
																? tierUpgrade
																: tierDowngrade
														}
														alt=""
													/>
												</div>
												<div>
													<p className="fontSize-16">
														<b>
															{tierInfo.action === 'UPGRADE'
																? `Membership Tier Upgrade - ${tierInfo.tier.name}`
																: `Membership Tier Downgrade - Bronze`}
														</b>
													</p>
													<p className="fontSize-14">
														{tierInfo.action === 'UPGRADE'
															? `Upgraded on ${formatTierChangeDate(
																	tierInfo.createdAt
															  )}`
															: `Downgrade on 2025`}
													</p>
												</div>
											</div>
										);
									})}
								</div>
							) : null}
						</div>

						{tierHistory && tierHistory.length ? (
							<div className="mt-2">
								{userData.totalUnitsBalance < 1000 ? (
									<p className="text-center fontSize-16">
										{userData?.tier?.name === 'Bronze'
											? 250 - userData.totalUnitsBalance
											: userData?.tier?.name === 'Silver'
											? 850 - userData.totalUnitsBalance
											: userData?.tier?.name === 'Gold'
											? 1850 - userData.totalUnitsBalance
											: null}{' '}
										Points to{' '}
										{userData?.tier?.name === 'Bronze'
											? t('Bronze')
											: userData?.tier?.name === 'Silver'
											? t('Silver')
											: userData?.tier?.name === 'Gold'
											? t('Platinum')
											: null}{' '}
										by {formatTierChangeDate(tierHistory[0].createdAt)}
									</p>
								) : null}
							</div>
						) : null}
					</div>
				)}
			</div>
			<UserFooter />
		</div>
	);
};

export default ProfilePage;
