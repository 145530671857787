import React from "react";
import { Button } from "antd";
import successImg from "../../../src/images/Haleon/Home/summarySuccess.svg";
import { useNavigate } from "react-router-dom";

const SummarySuccess = () => {
  const navigate = useNavigate();

  return (
    <div style={{ backgroundColor: "black", height: "100vh" }}>
      <div style={{ paddingTop: "120px" }}>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <img
            src={successImg}
            alt=""
            style={{ objectFit: "fill", width: "140px" }}
          />
        </div>
        <div className="text-center mt-1 t-white">
          <h1 className="font-family-hero-new">Success.</h1>
          <h3 className="mt-2 fontSize-16 font-family-hero-new font-weight-400">
            You have successfully completed your
            <br /> redemption.{" "}
          </h3>
        </div>
        <div
          className="mt-5"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <p
            className="t-white fontSize-12"
            style={{ maxWidth: "80%", lineHeight:'20px' }}
          >
            <b>E-Voucher Redemption</b>: Access your e-voucher in the "Rewards"
            section.
          </p>
          <p
            className="t-white fontSize-12 mt-1"
            style={{ maxWidth: "80%", lineHeight:'20px', margin:'0' }}
          >
            <b>Physical Product Redemption</b>: Your order will be delivered to
            you within 10 working days. If you chose Pick Up, visit your chosen
            outlet to redeem.
          </p>
        </div>
        <div
          style={{
            paddingBottom: "100px",
            justifyContent: "center",
            display: "flex",
          }}
        >
          <Button
            style={{
              backgroundColor: "#30E700",
              borderRadius: "5px",
              width: "85%",
              border: "none",
              height: "45px",
              padding: "12px 40px",
              fontSize: "16px",
              fontWeight: "500",
              marginTop: "100px",
              fontFamily: "Hero New",
            }}
            onClick={() => navigate("/rewards")}
          >
            Go To Rewards
          </Button>
        </div>
      </div>
    </div>
  );
};

export default SummarySuccess;
