import { Avatar, Badge, Button, Drawer, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import ComingSoon from './Modal/ComingSoon';
import { MenuOutlined, RightOutlined } from '@ant-design/icons';
import cart from '../images/Haleon/shoppingcart.svg';
import { checkUserSession, logoutUser } from '../reducer/userReducer';
import logo from '../images/Haleon/NavIcon/HaleonLogo.svg';
import sideBarLogo from '../images/Haleon/sideBarLogo.svg';
import { useNavigate } from 'react-router-dom';
import axiosClient from '../utils/axiosClient';
import { objectToURLSearchParamsString } from '../utils/axiosHelper';
import i18n from '../utilities/translation';

const MainHeader = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [open, setOpen] = useState(false);
	const [selectedLanguage, setSelectedLanguage] = useState("");
	const localtoken = localStorage.getItem('HALEON-REWARDS_USER_TOKEN');
	const [isSoon, setIsSoon] = useState(false);
	const isSGRegion =
		window.location.hostname.replace('www.', '') ===
		process.env.REACT_APP_SG_DOMAIN;
	const [userCarts, setUserCarts] = useState([]);
	const getLanguage = localStorage.getItem('HALEON-REWARDS_LANG');
	const fetchUserCarts = () => {
		const query = {
			rewardTypes: ['VOUCHER', 'PRODUCT'],
		};

		const stringQuery = objectToURLSearchParamsString(query);

		axiosClient
			.get('/carts?' + stringQuery)
			.then((result) => {
				if (result?.data?.data) {
					Array.isArray(result.data.data) && setUserCarts(result.data.data);
				} else {
					console.error('result: ', result);
				}
			})
			.catch((err) => {
				console.error('fetchUserCarts: ', err);
				message.error(err?.message || 'Error occur on fetching carts');
			});
	};

	useEffect(() => {
		if (!localtoken) {
			navigate('/welcome' + window.location.search);
		} else if (!isSGRegion) {
			fetchUserCarts();
		}
	}, [localtoken, isSGRegion]);

	const logOut = () => {
		dispatch(logoutUser());
		localStorage.removeItem('HALEON-REWARDS_USER_TOKEN');
		navigate('/login');
	};

	const showDrawer = () => {
		setOpen(true);
	};

	const onClose = () => {
		setOpen(false);
	};

	// languages handling
	const handleLanguageClick = (selectedLanguage) => {
		setSelectedLanguage(selectedLanguage);
		localStorage.setItem("HALEON-REWARDS_LANG", selectedLanguage);
		i18n.changeLanguage(selectedLanguage);
	  };
	  

	useEffect(() => {
	}, [getLanguage, selectedLanguage]);

	return (
		<div>
			<div className="mt-2">
				<div
					style={{
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
					}}
				>
					<div
						style={{
							justifyContent: 'left',
							display: 'flex',
							alignItems: 'center',
						}}
					>
						<img src={logo} alt="" className="ml-2 haleon-logo" />
					</div>
					<div
						style={{
							display: 'flex',
							justifyContent: 'right',
							marginRight: '20px',
							gap: '15px',
							alignItems: 'center',
						}}
					>
						{userCarts.length > 0 ? (
							<div>
								<Badge
									count={userCarts.length}
									style={{ backgroundColor: '#30E700', color: 'black', top: '5px'  }}
								>
									<Avatar
										src={cart}
										alt=""
										className="ml-1"
										style={{height: '50%', objectFit: 'contain', width: 'auto' }}
										onClick={() => navigate('/cartInfo')}
									/>
								</Badge>
							</div>
						) : (
						<Avatar
							src={cart}
							alt=""
							className="ml-1"
							style={{ width: '50%', height: '50%', objectFit: 'contain' }}
							onClick={() =>
								isSGRegion ? setIsSoon(true) : navigate('/cartInfo')
							}
						/>

						)}
						<div>
							<MenuOutlined
								style={{
									display: 'flex',
									alignItems: 'center',
									fontSize: '20px',
									color: '#474545',
								}}
								onClick={showDrawer}
							/>
						</div>
					</div>
				</div>
			</div>

			<div>
				<Drawer
					title={
						<div style={{ display: 'flex' }}>
							<div>
								<Button
									type="text"
									icon={<RightOutlined />}
									onClick={onClose}
									alt=""
									style={{
										backgroundColor: 'white',
										position: 'relative',
										right: '30px',
									}}
								/>
							</div>
							<img
								src={sideBarLogo}
								alt="Haleon Rewards Logo"
								style={{
									textAlign: 'center',
									position: 'relative',
									right: '10px',
								}}
							/>
						</div>
					}
					placement="right"
					onClose={onClose}
					open={open}
					closable={false}
					width={200}
					style={{ borderBottom: 'none', overflow: 'auto' }}
				>
					<div style={{ lineHeight: '2.5' }}>
						<p
							className="fontSize-14"
							style={{ marginBottom: '24px', marginTop: '10px' }}
							onClick={() => navigate('/help', { state: 'FAQ' })}
						>
							FAQ
						</p>
						<p
							className="fontSize-14"
							style={{ marginBottom: '24px' }}
							onClick={() => navigate('/help', { state: 'TNC' })}
						>
							Term & Condition
						</p>
						<p className="fontSize-14 t-black" style={{ marginBottom: '24px' }}>
							<a
								className="t-black"
								target="_blank"
								href={
									isSGRegion
										? 'https://www.privacy.haleon.com/en-sg/general/general-full-text/'
										: 'https://www.privacy.haleon.com/en-my/general/general-full-text/'
								}
								rel="noopener noreferrer"
							>
								Privacy Policy
							</a>
						</p>
						<p className="fontSize-14" style={{ marginBottom: '24px' }}>
							<a
								href="https://wa.me/+601154125382"
								target="_blank"
								rel="noopener noreferrer"
								style={{ textDecoration: 'none', color: 'black' }}
							>
								{' '}
								Contact Us
							</a>
						</p>
						{/* <p className="fontSize-14" style={{ marginBottom: '24px' }}>
							<a
								href="https://wa.me/+601154125382"
								target="_blank"
								rel="noopener noreferrer"
								style={{ textDecoration: 'none', color: 'black' }}
							>
								{' '}
								Unsubscribe
							</a>
						</p> */}
						<p className="fontSize-14" onClick={() => logOut()}>
							Log Out
						</p>
					</div>
					{/* uncoment after added translation of bm/ch */}
					{!isSGRegion && (
						<div
							style={{
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								gap: '5px',
								position: 'absolute',
								bottom: '20px',
								left: '50px',
								fontWeight: '600',
							}}
						>
							<p
								className="fontSize-14 font-weight-600"
								style={{
									textDecoration: getLanguage === 'en' && 'underline',
								}}
								onClick={() => handleLanguageClick('en')}
							>
								ENG
							</p>
							<p>|</p>
							<p
								className="fontSize-14 font-weight-600"
								style={{
									textDecoration: getLanguage === 'bm' && 'underline',
								}}
								onClick={() => handleLanguageClick('bm')}
							>
								BM
							</p>
							<p>|</p>
							<p
								className="fontSize-14 font-weight-600"
								style={{
									textDecoration: getLanguage === 'cn' && 'underline',
								}}
								onClick={() => handleLanguageClick('cn')}
							>
								CN
							</p>
						</div>
					)}
				</Drawer>
			</div>

			<ComingSoon isSoon={isSoon} setIsSoon={setIsSoon} />
		</div>
	);
};

export default MainHeader;
