import { Button, Carousel, Modal } from 'antd';
import React from 'react';
import receipt1 from '../../images/Haleon/receiptSample.png';
import receipt2 from '../../images/Haleon/receiptSample1.png';
import '../../css/UploadReceipt.css';
import { useTranslation } from 'react-i18next';

const SampleReceipt = ({ uploadSample, setUploadSample }) => {
	const { t } = useTranslation();
	return (
		<div>
			<Modal
				open={uploadSample}
				centered
				footer={null}
				header={null}
				width={320}
				// bodyStyle={{height: 380}}
				className="receipt-sample-modal"
				onCancel={() => setUploadSample(false)}
				closable={{ style: { backgroundColor: 'red' } }}
			>
				<div>
					<Carousel>
						<div className="text-center">
							<h2 className='font-family-hero-new fontSize-25 font-weight-600'>{t('Receipt Sample')}</h2>
							<div className="mt-1 text-center font-family-hero-new fontSize-16">
								{t('Sample of physical receipt')}:
							</div>
							<img src={receipt1} alt="purchase" className="w-100" />
						</div>

						<div>
							<h2 className="text-center font-family-hero-new fontSize-25 font-weight-600">{t('Receipt Sample')}</h2>
							<div className="mt-1 text-center font-family-hero-new fontSize-16">
								{t('Sample of e-commerce receipt')}:
							</div>
							<img
								src={receipt2}
								alt="Sample_Receipt2"
								style={{
									objectFit: 'contain',
									width: '50%',
									textAlign: 'center',
									display: 'block',
									justifyContent: 'center',
									margin: 'auto',
								}}
							/>
						</div>
					</Carousel>
				</div>
			</Modal>
		</div>
	);
};

export default SampleReceipt;
