import '../../css/Cart.css';
import { Button, Modal, Rate, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { redirect, useNavigate, useParams } from 'react-router-dom';
import SubHeader from '../../components/SubHeader';
// import Whatsapp from '../../components/Whatsapp';
import axiosClient from '../../utils/axiosClient';
// import delivery from '../../images/Haleon/delivery.svg';
import star from '../../images/Haleon/star.png';
export const list = {
	TNG: {
		HowToRedeem: [
			{
				en: [
					'Open the Touch `n Go eWallet Reload PIN App',
					'Click `Reload`',
					'Click `eWallet balance`',
					'Click on TNG eWallet Reload PIN below of the page',
					'Key in the TNG eWallet Reload PIN and click `Reload Now`',
					'Successfully redeem Touch `n Go eWallet voucher',
				],
				// vi: ` cho tất cả sản phẩm nào tại Tabimshop.com`,
			},
		],
		TNC: [
			{
				en: [
					'Only ONE (1) Reload PIN Reward can be used per reload.',
					'To utilize the Reload PIN, log in on the microsite. At `Rewards`, tap on the `View Now` button under your rewards. Then, tap on the copy icon next to the Voucher Code of the respective rewards and follow the steps provided',
					'Used Reload PIN Reward are non-reversible. Once used, the amount is not exchangeable nor refundable for credits, cash or such replacement in part or in full.',
					'TNG Digital will not in any way contact users for any personal information or account credentials for this Campaign.',
					'Reload PIN will be expired on 31 Mar 2024.',
				],
			},
		],
	},
	GRABGIFTS: {
		HowToRedeem: [
			{
				en: [
					'Open the Grab app and choose the “Gifts” Tab.',
					'Click the Gifts icon at the upper right corner',
					'Key-in your Gift Code',
					'Click Pick Your Gift, then proceed with saving the voucher',
					'Choose the gift',
					'Your saved voucher will be stored in “My Rewards”',
				],
			},
		],
		TNC: [
			{
				en: [
					'Users must enter promo code at checkout to be entitled to the discount.',
					'This promo code is valid for (1) ONE order from services on GrabFood, GrabRide, GrabExpress or GrabMart.',
					'This promo code will be expired on 31 May 2024.',
					'Promotion is not valid with any other vouchers or promotions.',
					'Grab reserves the right to alter, extend or terminate the promotion, or amend the terms and conditions at its sole discretion at any time without prior notice. In case of any disputes directly or indirectly arising from the promotion, the decision of Grab shall be final.',
					'These terms and conditions shall be governed by the laws of Malaysia and any dispute arising out of or in connection with promotion shall be referred to the exclusive jurisdiction of courts of Malaysia.',
				],
				// vi: ` cho tất cả sản phẩm nào tại Tabimshop.com`,
			},
		],
	},
	SPAY: {
		HowToRedeem: [
			{
				en: [
					'User must provide the details as below: ',
					'-SPayGlobal username / ID',
					'- Full Name',
					'- IC number ',
					'- Phone number (registered with SPayGlobal)',
					'It will be direct to the user wallet, please make sure your wallet is not maximum of the amount of the wallet RM3,000.',
					'SPAY Global shall have the sole and absolute discretion whether to provide you with the Services or not, herein.',
					'Kindly allow at least 7 working days for Sarawak Pay to direct debit the rewards to user’s wallet.',
				],
			},
		],
		TNC: [
			{
				en: [
					'User must provide the details as below: ',
					'-SPayGlobal username / ID',
					'- Full Name',
					'- IC number ',
					'- Phone number (registered with SPayGlobal)',
					'It will be direct to the user wallet, please make sure your wallet is not maximum of the amount of the wallet RM3,000.',
					'SPAY Global shall have the sole and absolute discretion whether to provide you with the Services or not, herein.',
					'Kindly allow at least 7 working days for Sarawak Pay to direct debit the rewards to user’s wallet.',
				],
			},
		],
	},
	SHOPEE: {
		HowToRedeem: [
			{
				en: [
					'Copy the voucher code',
					'Key in voucher code in "Shop voucher" on the checkout page',
					'Click "Place Order"',
				],
			},
		],
		TNC: [
			{
				en: [
					'Voucher valid for 30 days effective from the voucher delivery date',
					'Each user can only redeem once',
				],
			},
		],
	},
};
const RewardItems = () => {
	const navigate = useNavigate();
	const { id } = useParams();
	const [showMsg, setShowMsg] = useState(false);
	const [isOpen, setIsOpen] = useState(false);
	const [type, setType] = useState();
	const [isAddingToCart, setIsAddingToCart] = useState(false);
	const [shopItem, setShopItem] = useState(null);

	const isSGRegion =
		window.location.hostname.replace('www.', '') ===
		process.env.REACT_APP_SG_DOMAIN;

	const getShopItem = (_id) => {
		axiosClient
			.get('/shop-items/' + _id)
			.then((res) => {
				if (res?.data?.data) {
					setShopItem(res.data.data);
					setType(res.data.data.reward.type);
				}
			})
			.catch((err) => {
				console.error('getShopItem: ', err);
				redirect('/pointshop');
			});
	};

	useEffect(() => {
		if (id) {
			getShopItem(id);
		} else {
			navigate('/pointshop');
		}

		// dispatch(checkUserSession({ token: localtoken }));
	}, [id]);

	const handleAddToCart = () => {
		// Prevent multiple clicks while the action is processing
		if (isAddingToCart) {
			return;
		}

		const params = {
			shopItemId: shopItem.id,
			qty: 1,
		};

		axiosClient
			.post('/carts', params)
			.then((res) => {
				setShowMsg(true);
				setTimeout(() => {
					setShowMsg(false);

					setTimeout(() => {
						navigate('/cartInfo');
					}, 500);
				}, 1000);
			})
			.catch((err) => {
				// 	console.error('handleAddToCart: ', err);
				message.error(
					err?.response?.data?.message
						? err?.response?.data?.message
						: err?.message
						? err?.message
						: 'Error occur on add to cart.'
				);
			})
			.finally(() => setIsAddingToCart(false));
	};

	const handleToggleType = (newType) => {
		setType(newType);
	};

	// obtain the average rating for each physical item
	const calculateAverageRating = () => {
		if (shopItem && shopItem?.itemReview?.length > 0) {
			const totalRating = shopItem.itemReview.reduce(
				(acc, item) => acc + item.rating,
				0
			);
			return totalRating / shopItem.itemReview.length;
		}
		return 0;
	};

	const stockBalance = shopItem?.reward?.qtyMax - shopItem?.reward?.qtyUsed;
	const isOutOfStock = stockBalance <= 0 ? true : false;

	return (
		<div>
			<div>
				<SubHeader setPreviousPage={-1} />
			</div>
			{/* <div style={{ position: 'relative', top: '20px' }}>
				<Whatsapp />
			</div> */}
			<div
				style={{
					backgroundColor: '#F2F2F2',
					justifyContent: 'center',
					display: 'flex',
					height: '170px',
					alignItems: 'center',
				}}
				className="mt-3"
			>
				<div className="mt-2 text-center" style={{ paddingBottom: '10px' }}>
					<img
						src={shopItem?.reward?.imageUrl}
						alt=""
						style={{ width: '120px' }}
					/>
				</div>
			</div>

			<div className="ml-2 mr-2 mt-2" style={{ paddingBottom: '50px' }}>
				<div>
					<p style={{ fontSize: '24px', fontWeight: '700' }}>
						{shopItem?.name}
					</p>
				</div>

				{/* { shopItem?.name === "itemCategory" ? 
                    <div>
                        {shopItem.product.pickup_optional === "Y" ? 
                            <div>
                              <div style={{ borderRadius:'4px', backgroundColor:'#F5F5F5', display: 'flex', justifyContent:'center', width: '180px', alignItems:'center', gap:'5px' }} className='mt-1'>
                                  <img src={delivery} alt="" style={{width:'30px'}}/>
                                  <p className='fontSize-14' style={{fontWeight:'400'}}>Available for pick-up</p> 
                              </div>
                              <div className='mt-1'>
                                  <Rate value={foundRating.rating/foundRating.length}/>
                                  <span style={{ color: '#777'}} className='fontSize-16 ml-1'>{ foundRating.rating && <span className="ant-rate-text">{`${val[foundRating.rating/foundRating.length - 1]}/5 Stars`}</span> }</span>
                              </div> 
                            </div>
                        :
                            <div>
                              <div style={{ borderRadius:'4px', backgroundColor:'#F5F5F5', display: 'flex', justifyContent:'center', width: '130px', alignItems:'center', gap:'5px' }} className='mt-1'>
                                  <img src={delivery} alt="" style={{width:'30px'}}/>
                                  <p className='fontSize-14' style={{fontWeight:'400'}}>Delivery Only</p>
                              </div>
                              <div className='mt-1'>
                              <Rate value={averageRating}/>
                                <span style={{ color: '#777'}} className='fontSize-16 ml-1'>
                                   { `${averageRating}/5 Stars`}
                                </span>
                              </div> 
                            </div>

                        }
                    </div>
                : 
                    null 
                } */}
				{/* {shopItem?.reward?.type === 'PRODUCT' && (
					<div>
						<div>
							<div
								style={{
									borderRadius: '4px',
									backgroundColor: '#F5F5F5',
									display: 'flex',
									justifyContent: 'center',
									width: '130px',
									alignItems: 'center',
									gap: '5px',
								}}
								className="mt-1"
							>
								<img src={delivery} alt="" style={{ width: '30px' }} />
								<p className="fontSize-14" style={{ fontWeight: '400' }}>
									Delivery Only
								</p>
							</div>
							<div className="mt-1">
								<Rate value={calculateAverageRating()} disabled />
								<span style={{ color: '#777' }} className="fontSize-16 ml-1">
									{`${calculateAverageRating().toFixed(1)}/5 Stars`}
								</span>
							</div>
						</div>
					</div>
				)} */}

				<div
					className="mt-1 fontSize-16"
					style={{
						fontWeight: '400',
						color: '#424242',
					}}
				>
					{shopItem?.reward?.description
						? shopItem?.description
						: ''}
				</div>

				<div className="mt-2" style={{ paddingBottom: '100px' }}>
					{/* {shopItem?.reward?.type === 'PRODUCT' && (
						<div style={{ justifyContent: 'space-between', display: 'flex' }}>
							<p
								style={{
									color: type === 'PRODUCT' ? 'black' : '#777',
									fontWeight: '600',
								}}
								className="fontSize-16"
								onClick={() => handleToggleType('PRODUCT')}
							>
								Product Information
							</p>
							<p
								style={{
									color: type === 'REVIEWS' ? 'black' : '#777',
									fontWeight: '600',
								}}
								className="fontSize-16"
								onClick={() => handleToggleType('REVIEWS')}
							>
								Review
							</p>
						</div>
					)} */}

					{shopItem?.reward?.type === 'VOUCHER' && (
						<div style={{ justifyContent: 'space-between', display: 'flex' }}>
							<p
								style={{
									color: type === 'VOUCHER' ? 'black' : '#777',
									fontWeight: '600',
								}}
								className="fontSize-16"
								onClick={() => handleToggleType('VOUCHER')}
							>
								How To Redeem
							</p>
							<p
								style={{
									color: type === 'TNC' ? 'black' : '#777',
									fontWeight: '600',
								}}
								className="fontSize-16"
								onClick={() => handleToggleType('TNC')}
							>
								T&C
							</p>
						</div>
					)}

					{type === 'PRODUCT' && (
						<div>
							<ol
								style={{
									padding: '0 20px',
									fontSize: '14px',
									textAlign: 'justify',
									textJustify: 'auto',
									fontWeight: '400',
									color: '#424242',
									marginBottom: '60px',
								}}
							>
								<li className="my-1">
									Go to your shopping cart and select your purchases to "Check
									Out".
								</li>
								<li className="my-1">At the Checkout page.</li>
								<li className="my-1">
									Enter your promotion code on the Gift Card in the "Enter
									Voucher Code" field and tap on the "Apply" button to redeem
									your Gift Card.
								</li>
								<li className="my-1">
									Once applied, continue to check out by tapping on the "Proceed
									to Checkout" button for the website or "Place Order" button
									for the app to continue the payment and complete the purchase.
								</li>
							</ol>
						</div>
					)}

					{type === 'Reviews' && (
						<div style={{ paddingBottom: '20%' }}>
							{shopItem && shopItem?.itemReview?.length ? (
								shopItem?.itemReview.map((ele, index) => {
									return (
										<div
											style={{
												marginTop: '5%',
												borderRadius: '8px',
												border: '0.5px solid #D8D8D8',
												padding: '12px 16px',
												alignItems: 'center',
											}}
											key={index}
										>
											<div
												style={{
													display: 'flex',
													justifyContent: 'space-between',
												}}
											>
												<div>
													<p
														style={{ fontWeight: '600' }}
														className="fontSize-16"
													>
														{ele.companyUser.name}
													</p>
													<p
														style={{ color: '#7C878E', fontWeight: '400' }}
														className="fontSize-16"
													>
														{ele.createdAt}
													</p>
													<p
														style={{ fontWeight: '400' }}
														className="fontSize-16"
													>
														{ele.comment}
													</p>
												</div>
												<div
													style={{
														display: 'flex',
														justifyContent: 'right',
														alignItems: 'center',
														width: '35%',
														gap: '5px',
													}}
												>
													<img
														src={star}
														alt="ratingStar"
														style={{ width: '20%' }}
													/>
													<p
														style={{
															fontWeight: '640000',
															fontSize: '24px',
														}}
													>
														{ele.rating}
													</p>
												</div>
											</div>
										</div>
									);
								})
							) : (
								<p>No review yet.</p>
							)}
						</div>
					)}

					{type === 'VOUCHER' && shopItem?.reward?.category && (
						<div>
							{isSGRegion ? (
								<div>
									{shopItem?.reward?.category[0] === 'FP'?  (
										<div style={{padding: '1rem 2rem'}}> 
											<ol>
												<li>Click "click to redeem".</li>
												<li>Shop online at <a href="http://fairprice.com.sg" target="blank">http://fairprice.com.sg</a></li>
											</ol>
											<div style={{marginTop: '1rem'}}>
												STEP 1: Add vouchers at Checkout. Click ‘Add’ next to the Voucher code.
												<br />
												STEP 2: Use your stored e-vouchers. Select one or more e-vouchers you would like to use, or manually enter the e-voucher code by copying and pasting, or enter your e-voucher code into the 'Enter voucher code' field and proceed to checkout.
											</div>
										</div>
									):('')}
									{shopItem?.reward?.category[0] === 'WATSONS'?  (
										<div style={{padding: '1rem 0'}}> 
											<list>
												The e-Voucher code provided will be applicable for instore use only (except Jewel). Please provide the e-voucher to the cashier counter only (not applicable at Self-Checkout Kiosk and at Scan & Go). Not applicable at Atriums, Pop-up stores and Watsons Online.
											</list>
										</div>
									):('')}
									{shopItem?.reward?.category[0] === 'GUARDIAN'?  (
										<div style={{padding : '1rem'}}> 
											<ol>
												<li>Copy the link and paste it on your browser search bar</li>
												<li>Please present the voucher page on your mobile device to the cashier at the time of redemption</li>
												<li>No printouts or screenshots will be accepted. The dark mode on the phone has to be switched off</li>
											</ol>
										</div>
									):('')}
								</div> 
							) : (
								<div>
									{Object.keys(list).map(
										(voucherType) =>
											shopItem.reward.category.includes(voucherType) && (
												<div key={voucherType}>
													{list[voucherType].HowToRedeem.map(
														(redeemItem, redeemIndex) => (
															<div key={redeemIndex}>
																<ol
																	className="mt-3 ml-3"
																	style={{
																		fontFamily: 'Hero New, Arial',
																		fontSize: '14px',
																		lineHeight: '1.5rem',
																	}}
																>
																	{redeemItem['en'].map((line, index) =>
																		line.startsWith('-') ? (
																			<div key={index}>
																				<p
																					style={{
																						fontFamily: 'Hero New, Arial',
																						fontSize: '14px',
																					}}
																					key={index}
																				>
																					{line}
																				</p>
																			</div>
																		) : (
																			<li key={index}>{line}</li>
																		)
																	)}
																</ol>
															</div>
														)
													)}
												</div>
											)
									)}
								</div>
							)}
						</div>
					)}

					{type === 'TNC' && shopItem?.reward?.category && (
						<div>
							{isSGRegion ? (
								<div>
									{shopItem?.reward?.category[0] === 'FP'?  (
										<div style={{padding: '1rem 2rem'}}> 
											<ol>
												<li>Expressed in Singapore dollars.</li>
												<li>Not valid for purchase of lottery products.</li>
												<li>Not valid for redemption at Unity Changi Airport.</li>
												<li>Not valid for redemption at Cheers and FairPrice Xpress outlets</li>
												<li>Non-exchangeable for cash and/or gift vouchers.</li>
												<li>Non-refundable and/or non-exchangeable in event of loss, theft or damage</li>
												<li>Non-refundable for any unused amount/value.</li>
												<li>Non-renewable upon expiry. The expiry date is final.</li>
											</ol>
										</div>
									):('')}
									{shopItem?.reward?.category[0] === 'WATSONS'?  (
										<div style={{padding: '1rem 2rem'}}> 
											<ol>
												<li>The e-Voucher is redeemable only at Watsons physical stores except at Jewel.</li>
												<li>The e-Voucher entitles the bearer to redeem products of equivalent value in Singapore only.</li>
												<li>The e-Voucher is not redeemable for purchases made via Self-Checkout Kiosk / Scan & Go.</li>
												<li>The e-Voucher cannot be used at Pop-up stores, Watsons atrium events & Watsons Online.</li>
												<li>Any returned product purchased using this e-voucher can only be exchanged for another product of a lower or equivalent value.</li>
												<li>The e-Voucher is not exchangeable for cash and not replaceable if lost under any circumstances.</li>
												<li>Extension of e-voucher validity is strictly not allowed.</li>
												<li>Watsons reserves the right of final decision to refuse the use of any e-voucher in any cases of dispute.</li>
												<li>Watsons reserves the right to amend the terms and conditions without prior notice.</li>
											</ol>
										</div>
									):('')}
									{shopItem?.reward?.category[0] === 'GUARDIAN'?  (
										<div style={{padding : '1rem 2rem'}}> 
											<ol>
												<li>This voucher is valid only within Singapore (excluding airport stores) and its value is equivalent to the Singapore currency.</li>
												<li>This voucher will expire in one month upon redemption.</li>
												<li>No extension upon expiry. The expiry date is final.</li>
												<li>Not refundable and cannot be replaced if lost, stolen or damaged.</li>
												<li>Not refundable or exchangeable for cash, and any unused balance will not be refunded.</li>
												<li>Not valid for purchase of all lottery products, bill payments, top-up services, Dairy Farm gift vouchers and Qoo10 online shopping payment.</li>
												<li>This voucher must be fully utilised in a single transaction and any unconsumed value will be forfeited and is not refundable.</li>
												<li>Cold Storage Singapore (1983) Pte Ltd reserves the right to refuse or suspend the redemption of vouchers upon unsuccessful validation at the Points of Sales.</li>
												<li>Cold Storage Singapore (1983) Pte Ltd reserves the right to amend the terms and conditions from time to time without prior notice. Consumer advisory – Cold Storage Singapore (1983) Pte Ltd, the holder of the gift Voucher does not require the approval of the Monetary Authority of Singapore. Consumers (users) are advised to read the terms and conditions carefully.</li>
											</ol>
										</div>
									):('')}
								</div> 
							) : (
								<div>
									{Object.keys(list).map((voucherType) => {
										if (shopItem.reward.category.includes(voucherType)) {
											return (
												<div key={voucherType}>
													{list[voucherType].TNC.map((redeemItem, redeemIndex) => (
														<div key={redeemIndex}>
															{voucherType === 'TNG' ||
															voucherType === 'GRABGIFTS' ? (
																<ul
																	className="mt-3"
																	style={{
																		fontFamily: 'Hero New, Arial',
																		fontSize: '14px',
																		listStyle: '10px',
																		lineHeight: '1.6rem',
																		marginLeft: '15px',
																	}}
																>
																	{redeemItem['en'].map((line, index) => (
																		<li key={index}>{line}</li>
																	))}
																</ul>
															) : (
																<ol
																	className="mt-3 ml-3"
																	style={{
																		fontFamily: 'Hero New, Arial',
																		fontSize: '14px',
																		listStyle: '10px',
																		lineHeight: '1.5rem',
																	}}
																>
																	{redeemItem['en'].map((line, index) =>
																		line.startsWith('-') ? (
																			<div key={index}>
																				<p
																					style={{
																						fontFamily: 'Hero New, Arial',
																						fontSize: '14px',
																					}}
																					key={index}
																				>
																					{line}
																				</p>
																			</div>
																		) : (
																			<li key={index}>{line}</li>
																		)
																	)}
																</ol>
															)}
														</div>
													))}
												</div>
											);
										} else {
											return null;
										}
									})}
								</div>
							)}
						</div>
					)}
				</div>

				<div>
					{/* <div>
                  <img src={circle} alt="circle" style={{width:'80px'}}/>
                </div> */}
				</div>
			</div>

			{!isOutOfStock ? (
				<div className="add-to-cart-container">
					<div
						style={{
							display: 'flex',
							justifyContent: 'space-between',
							alignItems: 'center',
							marginTop: '20px',
						}}
					>
						<div className="ml-3">
							<span className='font-family-hero-new' style={{ fontSize: '48px', fontWeight: '700' }}>
								{shopItem?.priceValue}
							</span>
							<span
								style={{
									fontWeight: 'none',
									marginLeft: '5px',
									fontSize: '24px',
								}}
								className='font-family-hero-new'
							>
								pts
							</span>
						</div>
						<div className="mr-2">
							<Button
								style={{
									opacity: isAddingToCart ? '0.5' : '1',
									backgroundColor: '#30E700',
									border: 'none',
									borderRadius: '5px',
									fontWeight: '500',
								}}
								className="fontSize-16 text-center font-family-hero-new"
								onClick={() => {
									if (isAddingToCart) {
										return;
									}
									setIsAddingToCart(true);
									handleAddToCart();
								}}
							>
								{isAddingToCart ? 'Adding...' : 'Add To Cart'}
							</Button>
						</div>
					</div>
				</div>
			) : (
				<div className="add-to-cart-container">
					<div
						style={{
							display: 'flex',
							justifyContent: 'space-between',
							alignItems: 'center',
							marginTop: '20px',
						}}
					>
						<div className="ml-3">
							<span style={{ fontSize: '48px', fontWeight: '700' }}>
								{shopItem?.priceValue}
							</span>
							<span
								style={{
									fontWeight: 'none',
									marginLeft: '5px',
									fontSize: '24px',
								}}
							>
								pts
							</span>
						</div>
						<div className="mr-2">
							<Button
								style={{
									backgroundColor: '#30E700',
									border: 'none',
									borderRadius: '5px',
									fontWeight: '500',
								}}
								className="fontSize-16 text-center"
								disabled
							>
								Out Of Stock
							</Button>
						</div>
					</div>
				</div>
			)}

			{showMsg && (
				<div
					style={{
						position: 'fixed',
						bottom: '170px',
						left: '50%',
						transform: 'translateX(-50%)',
						padding: '8px 20px',
						backgroundColor: '#ABEA9A',
						color: '#2B7218',
						borderRadius: '22px',
						boxShadow: '0px 2px 12px 0px rgba(0, 0, 0, 0.12)',
						textAlign: 'center',
					}}
					className="add-to-cart-width"
				>
					Added to cart successfully!
				</div>
			)}

			<div>
				<Modal
					open={isOpen}
					centered
					footer={null}
					width={350}
					onCancel={() => setIsOpen(false)}
				>
					<div className="text-center">
						<div className="mt-1">
							<p
								style={{
									fontSize: '18px',
									width: 'auto',
									fontWeight: '500',
									marginTop: '20px',
									textAlign: 'center',
								}}
							>
								Review(s)
							</p>

							<div className="mt-2">
								{shopItem && shopItem?.itemReview?.length > 0 ? (
									shopItem.itemReview.map((ele, index) => {
										const numbering = `${index + 1}. `;

										return (
											<div
												key={index}
												style={{
													display: 'flex',
													justifyContent: 'space-between',
												}}
											>
												<p className="text-center">
													{numbering}
													{ele.comment}
												</p>
											</div>
										);
									})
								) : (
									<div>
										<p className="text-center">
											Please check back the reviews later
										</p>
									</div>
								)}
							</div>
						</div>
					</div>
				</Modal>
			</div>
		</div>
	);
};

export default RewardItems;
