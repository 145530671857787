import { Modal, Button } from "antd";
import grab from "../../images/Haleon/grab_green.svg"
import { useTranslation } from "react-i18next";
import '../../css/HowToRedeem.css';

const HowToRedeemGrab = ({ redeemGrab, setRedeemGrab }) => {
const {t} = useTranslation();
  return (
    <Modal 
      open={redeemGrab} 
      centered 
      footer={null} 
      closable={false}
      width = {365}
    >
      <div className="text-center mt-1" style={{margin: "10px 0"}}>
        <img
          style={{ width: "40%" }}
          src={grab}
          alt=""
        />
        <div className= "mt-1" style={{ lineHeight:'25px' }}>
        <h3
        style={{
          fontSize:'20px', 
          width:'auto',
          fontFamily:"Hero New",
        }}
        >
          {t('How to Redeem Your GrabGift?')}
        </h3>
        </div>
        <div className="mt-2">
        <ol
          style={{
            padding: "0 15px",
            fontSize: "14px",
            textAlign: "justify",
            textJustify: "auto",
            fontFamily: "Hero New",
            marginLeft: "10px" 
          }}          
        >
          <li className="my-1">
          {t('Open the Grab app and choose the “Gifts” Tab.')}
          </li>
          <li className="my-1">
          {t('Click the Gifts icon at the upper right corner')}
          </li>
          <li className="my-1">
          {t('Key-in your Gift Code')}
          </li>
          <li className="my-1">
          {t('Click Pick Your Gift, then proceed with saving the voucher')}
          </li>
          <li className="my-1">
          {t('Choose the gift')}
          </li>
          <li className="my-1">
          {t('Your saved voucher will be stored in “My Rewards”')}
          </li>
        </ol>
        </div>
        <Button
          type="primary done-button"
          className="mt-1"
          onClick={() => setRedeemGrab(false)}
        >
         {t('DONE')}
        </Button>  
      </div>
    </Modal>
  );
};

export default HowToRedeemGrab;