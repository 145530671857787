import '../../css/Reward.css';
import { Button, Input, Select, Space } from 'antd';
import React, { useEffect, useState } from 'react';
import MainHeader from '../../components/MainHeader';
import { SearchOutlined } from '@ant-design/icons';
import UserFooter from '../../components/UserFooter';
import Whatsapp from '../../components/Whatsapp';
import axiosClient from '../../utils/axiosClient';
import debounce from 'lodash/debounce';
import star from '../../images/Haleon/star.svg';
import { useNavigate } from 'react-router-dom';
import { objectToURLSearchParamsString } from '../../utils/axiosHelper';
import { sortShopItems } from '../../utils/misc/sortShopItems';

const PointShop = ({ t }) => {
	const navigate = useNavigate();
	const [searchByName, setSetSearchByName] = useState(null);
	const [filterBy, setFilterBy] = useState();
	const [selectedCategoryId, setSelectedCategoryId] = useState('All');
	const [shopItems, setShopItems] = useState([]);
	// const [categories, setCategories] = useState([]);
	const [userTotalUnitsBalance, setUserTotalUnitsBalance] = useState(0);

	const getShopItemsWithQueries = (
		_searchByName,
		_filterBy,
		_selectedShopItemCategory
	) => {
		const query = {
			// DEFAULT:
			rewardTypes: ['VOUCHER', 'PRODUCT'],

			// CUSTOM FILTER:
			...(_searchByName && { name: _searchByName }),
			...(filterBy && { filterBy: _filterBy }),
			...(_selectedShopItemCategory &&
				_selectedShopItemCategory !== 'All' && {
					shopItemCategoryId: _selectedShopItemCategory,
				}),
			perPage: 100,
		};

		const stringQuery = objectToURLSearchParamsString(query);
		axiosClient
			.get('/shop-items?' + stringQuery)
			.then((res) => res.data)
			.then(({ data }) => {
				if (data) {
					Array.isArray(data?.shopItems) && setShopItems(data?.shopItems);
					// Array.isArray(data?.shopItemCategories) &&
					// 	setCategories(data?.shopItemCategories);
					data.userTotalUnitsBalance &&
						setUserTotalUnitsBalance(Number(data.userTotalUnitsBalance));
				}
			})
			.catch((err) => {
				console.error('getShopItemsWithQueries: ', err);
			});
	};

	useEffect(() => {
		getShopItemsWithQueries(searchByName, filterBy, selectedCategoryId);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchByName, filterBy, selectedCategoryId]);

	const handleOnSelectFilterBy = (_filterBy) => {
		setFilterBy(_filterBy);
	};

	const handleOnSelectCategory = (_cate) => {
		setSelectedCategoryId(_cate);
	};

	const handleOnSearchByName = debounce(
		(_search) => setSetSearchByName(_search),
		500
	);

	return (
		<div>
			<div>
				<MainHeader />
			</div>
			{/* <Whatsapp /> */}

			<div className="ml-2 mr-2">
				<div
					className="mt-5"
					style={{
						justifyContent: 'space-evenly',
						display: 'flex',
						gap: '8px',
					}}
				>
					<Button
						className="btn-color font-family-hero-new font-weight-500"
						style={{ backgroundColor: 'black', color: 'white' }}
						onClick={() => navigate('/pointshop')}
					>
						{' '}
						{t('Point Shop')}
					</Button>

					<Button
						className="btn-color font-family-hero-new font-weight-500"
						style={{ backgroundColor: '#C2C2C2', color: 'white' }}
						onClick={() => navigate('/rewards')}
					>
						{' '}
						{t('My Rewards')}
					</Button>
				</div>

				<div>
					<div className="mt-3">
						<Space.Compact style={{ width: '100%' }}>
							<Input
								size="small"
								prefix={<SearchOutlined />}
								placeholder=" Search for a reward"
								onChange={(e) => {
									handleOnSearchByName(e.target.value);
								}}
								style={{
									fontSize: '16px',
								}}
							/>
						</Space.Compact>
					</div>

					<div
						className="mt-2 scroll-img"
						style={{ justifyContent: 'left', display: 'flex', gap: '8px' }}
					>
						{/* {[{ id: 'All', name: 'All' }, ...categories].map( */}
						{[{ id: 'All', name: 'All' }].map((category, index) => {
							return (
								<Button
									key={index}
									className={
										`${selectedCategoryId === category.id ? 'redeemItem-active-btn' : 'redeemItem-inactive-btn'} font-family-hero-new`
									  }
									  
									// style={{ flex: 1, width: '100px' }}
									style={{ width: '20%'}}
									onClick={() => handleOnSelectCategory(category?.id)}
								>
									{category.name}
								</Button>
							);
						})}
					</div>

					<div
						style={{
							display: 'flex',
							justifyContent: 'space-between',
							marginTop: '20px',
						}}
					>
						{/* <div> */}
						<div className='total-balance'
							style={{
								width: userTotalUnitsBalance >= 1000 ? '120px' : '100px',
							}}
						>
							<img src={star} alt="star" style={{ width: '20px' }} />
							<p>
								<b>{userTotalUnitsBalance || 0}</b> pts
							</p>
						</div>
						{/* </div> */}
						<div>
							<Select
								value={filterBy}
								placeholder={t('Filter By')}
								className="select-tag text-center"
								style={{ textAlign: 'center'}}
								onChange={handleOnSelectFilterBy}
								// dropdownClassName="select-dropdown"
								popupClassName="select-dropdown"
							>
								<Select.Option key="1" value={'isRecommended'}>
									Recommended
								</Select.Option>
								<Select.Option key="2" value={'clear'}>
									Clear Filter
								</Select.Option>
							</Select>
						</div>
					</div>
					{/* <div
						style={{
							position: 'relative',
							top: '20px',
							left: '0',
							width: '90%',
						}}
					>
						<Whatsapp />
					</div> */}

					<div
						className="pointShop-container mt-1"
						style={{ paddingBottom: '100px' }}
					>
						{sortShopItems(shopItems).map(({ id, name, priceValue, reward }, index) => {
							const stockBalance = reward?.qtyMax - reward?.qtyUsed;
							const isOutOfStock = stockBalance <= 0;
							const handleClick = () => {
								if (!isOutOfStock) {
									navigate('/rewards/items/' + id);
								}
							};

							return (
								<div
									className="text-center mt-1"
									key={index}
									onClick={handleClick}
									style={{ cursor: isOutOfStock ? 'not-allowed' : 'pointer' }}
								>
									<div
										className="d-flex reward-img"
										style={{
											opacity: isOutOfStock ? 0.5 : 1,
										}}
									>
										<img
											src={reward?.imageUrl}
											alt=""
											style={{
												width:
													reward?.voucherType === 'SHOPEE' ? '120px ' : '90px',
												objectFit: 'contain',
												height: '80px',
											}}
										/>
									</div>
									<div style={{ textAlign: 'left', marginTop: '5%' }}>
										<h2
											className="fontSize-14 font-family-hero-new"
											style={{ fontWeight: '600', width: '88%' }}
										>
											{name}
										</h2>
										<h2
											className="fontSize-16 font-family-hero-new"
											style={{ fontWeight: '700', color: isOutOfStock ? '#C4113C' : '#30EA03', }}
										>
											{isOutOfStock ? 'Out of Stock' : `${priceValue}pts`}
										</h2>
									</div>
								</div>
							);
						})}
					</div>
				</div>
			</div>

			{/* Footer */}
			<UserFooter type={'rewardsPage'} />
		</div>
	);
};

export default PointShop;
