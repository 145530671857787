import '../../css/Register.css';
import RegisterForm from '../../components/forms/RegisterForm';
import img1 from '../../images/Haleon/happyFamily.png';
import { useEffect } from 'react';
import haleonLogo from '../../images/Haleon/HaleonLogo.svg';
import { useLocation } from 'react-router-dom';

const RegisterPage = ({ t }) => {
	// const location = useLocation();
	// const isSGRegion =
	// 	window.location.hostname.replace('www.', '') ===
	// 	process.env.REACT_APP_SG_DOMAIN;
	const otp = localStorage.getItem('tempOtp');
	useEffect(() => {
		if(!otp){
			window.location.replace('/welcome' + window.location.search);
		}
	}, [otp]);
	return (
		<div className="text-center">
			<div className="logo-position">
				<img src={haleonLogo} alt="haleon" style={{ width: '30%' }} />
				{/* <Whatsapp/> */}
			</div>
			<img src={img1} alt="pic" className="w-100" />
			<div className="layout-position">
				<div className="register-card" style={{ textAlign: 'center' }}>
					<br />
					<h1 style={{ fontFamily: 'Hero New', fontSize:'32px', fontWeight:'600', }}>{t('Registration')}</h1>
					<div className="mt-1"></div>
					<hr className='green-line'/>
					<p className="mt-1 text-center" style={{ fontFamily: 'Hero New', fontWeight: '400', }}>
						{t('Please complete the form to proceed')}
					</p>
					<div className="mt-3">
						<RegisterForm
							formConfig={{
								label: 'Number',
								placeholder: 'Enter Your Phone Number',
								layout: 'vertical',
							}}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default RegisterPage;
