import '../css/Home.css';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axiosClient from '../utils/axiosClient';
import noImage from '../images/Haleon/imageUnavailable.jpeg';
import { useTranslation } from 'react-i18next';
const ScrollableImages = () => {
	const navigate = useNavigate();
	const [displayItem, setDisplayItem] = useState([]);

    useEffect(() => {
		const check = async () => {
			try {
				const res = await axiosClient.get('/shop-items');
				// console.log('res', res.data.data.shopItems);
				setDisplayItem(res.data.data.shopItems);
			} catch (error) {
				// navigate(`/login?store=${store}`);
			}
		};
		check();
	}, []);

	return (
		<div
			className="mt-2 scroll-img"
			style={{ display: 'flex', justifyContent: 'left', gap: '20px' }}
		>
			{displayItem && displayItem.length ? (
				displayItem
					.slice(7,11)
					.map((getItem, index) => {
						const handleClick = () => {
							navigate('/rewards/items/' + getItem.id, { state: { getItem } });
						};
						return (
							<div
								className="text-center ml-1"
								onClick={handleClick}
								key={index}
							>
								<div
									className="d-flex"
									style={{
										borderRadius: '20px',
										border: '1px solid #D9D9D9',
										width: '120px',
										height: '120px',
										alignItems: 'center',
										justifyContent: 'center',
									}}
								>
									<img
										src={
											getItem.reward.imageUrl
												? getItem.reward.imageUrl
												: noImage
										}
										alt=""
										style={{ width: '60%' }}
									/>
								</div>
								<div style={{ textAlign: 'left', width: '120px' }}>
									<h2
										className="fontSize-14 font-family-hero-new font-weight-500"
										style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}
									>
										{getItem?.name}<br/>
										{getItem?.reward?.type}
									</h2>
									<h2 className="fontSize-16 font-family-hero-new" style={{color:'#30E700'}}>{getItem?.priceValue} pts</h2>
								</div>
							</div>
						);
					})
			) : (
				<div>
					<p className="text-center">Stay Tuned for more exciting rewards!</p>
				</div>
			)}
		</div>
	);
};

export default ScrollableImages;
