import '../../css/Submissions.css';
import { Button, Modal, Select } from 'antd';
import React, { useEffect, useState } from 'react';
// import {
// 	checkUserSession,
// 	checkVouchers,
// 	fetchHistory,
// } from '../../reducer/userReducer';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import MainHeader from '../../components/MainHeader';
import UserFooter from '../../components/UserFooter';
import moment from 'moment';
import { useNavigate } from 'react-router';
import welcome from '../../images/Haleon/welcome.png';
// import silverMedal from '../../images/Haleon/silverMedal1.png';
// import bronzeMedal from '../../images/Haleon/bronzeMedal.png';
// import goldMedal from '../../images/Haleon/goldMedal1.png';
import deliveryIcon from '../../images/Haleon/delivery.svg';
// import Whatsapp from '../../components/Whatsapp';
import VoucherUnavailable from '../../components/Modal/VoucherUnavailable';
import axiosClient from '../../utils/axiosClient';
import notAvailableImg from '../../images/Haleon/imageUnavailable.jpeg';
import receiptImg from '../../images/Haleon/receipt1.png';
import multiplier from '../../images/Haleon/multiplier.png';
import referral from '../../images/Haleon/referral.png';

const Submissions = ({ t }) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();
	const [tab, setTab] = useState('Submissions');
	// const localtoken = localStorage.getItem('HALEON-REWARDS_USER_TOKEN');
	// const [filter, setFilter] = useState();
	const [submission, setSubmission] = useState(false);
	const [points, setPoints] = useState(false);
	const [submissionData, setSubmissionData] = useState();
	const [submissionModal, setSubmissionModal] = useState(false);
	const [view, setView] = useState(false);
	const [uploadHistory, setUploadHistory] = useState();
	const [pointHistory, setPointHistory] = useState();
	const isSGRegion =
		window.location.hostname.replace('www.', '') ===
		process.env.REACT_APP_SG_DOMAIN;
	const uploadReceiptForVoucherRewardCampaignId = isSGRegion
		? 'c898ecf5-4ddc-4702-813f-c8f557b5b74e'
		: '1a82cf7d-1e40-44a9-8687-815855835b9d';
	const userToken = localStorage.getItem(`HALEON-REWARDS_USER_TOKEN`);
	const getLanguage = localStorage.getItem('HALEON-REWARDS_LANG');

	const handleFilterChange = async (value) => {
		try {
			let dateFilter;
			if (value === t('Filter By')) {
				const currentMonth = new Date().getMonth() + 1;
				dateFilter = currentMonth.toString();
			} else {
				dateFilter = value;
			}
			try {
				const submissionRes = await axiosClient.get('/submissions', {
					params: {
						campaignId: uploadReceiptForVoucherRewardCampaignId,
					},
				});
				if (submissionRes && submissionRes.data) {
					setUploadHistory(submissionRes.data.getFinalSubmissionInfo);
				}
			} catch (e) {
				console.error(
					'Error fetching submission details:',
					e?.response?.data?.message
				);
			}

			try {
				const pointsRes = await axiosClient.get('/points/get');
				setPointHistory(pointsRes.data.data);
			} catch (e) {
				console.error(
					'Error fetching points history:',
					e?.response?.data?.message
				);
			}

			// Rest of the handleFilterChange logic
			const checkSubmissions = await axiosClient.get('/submissions', {
				params: {
					campaignId: uploadReceiptForVoucherRewardCampaignId,
					filter: dateFilter,
				},
			});

			const checkPoints = await axiosClient.get('/points/get', {
				params: { filter: dateFilter },
			});
			if (checkSubmissions.data.getFinalSubmissionInfo.length > 0) {
				const pointHistoryWithReceiptAmount = checkPoints.data.data.map(point => {
				  const matchingSubmission = checkSubmissions.data.getFinalSubmissionInfo.find(
					submission => point.sourceId === submission.getSubmissionInfo.id
				  );
				  return {
					...point,
					receiptAmount: matchingSubmission?.getSubmissionInfo?.totalReceiptAmount || 0
				  };
				});
				checkPoints.data.data = pointHistoryWithReceiptAmount;
			}
			setUploadHistory(checkSubmissions.data.getFinalSubmissionInfo || []);
			setPointHistory(checkPoints.data.data || []);
		} catch (error) {
			console.error('Error fetching data:', error);
		}
	};

	useEffect(() => {
		if (!userToken) {
			navigate('/welcome' + window.location.search);
		} else {
			handleFilterChange(t('Filter By'));
		}
	}, [userToken]);

	// useEffect(() => {
	// 	const checkSubmissionDetails = async () => {
	// 		try {
	// 			const res = await axiosClient.get('/submissions', {
	// 				params: {
	// 					campaignId: uploadReceiptForVoucherRewardCampaignId,
	// 				},
	// 			});
	// 			if (res && res.data) {
	// 				setUploadHistory(res.data.getFinalSubmissionInfo);
	// 			}
	// 		} catch (e) {
	// 			console.error('e', e?.response?.data?.message);
	// 		}
	// 	};
	// 	checkSubmissionDetails();

	// 	const checkPointsHistory = async () => {
	// 		try {
	// 			const res = await axiosClient.get('/points/get');
	// 			// console.log('res', res);
	// 			setPointHistory(res.data.data);
	// 			// console.log('res', res.data.data);
	// 		} catch (e) {}
	// 	};
	// 	checkPointsHistory();
	// }, []);

	const items = [
		{
			key: '1',
			label: t('Overall'),
			value: 'overall',
		},
		{
			key: '2',
			label: t('Last Month'),
			value: 'last_month',
		},
		{
			key: '3',
			label: t('Last 3 Months'),
			value: 'last_three_months',
		},
		{
			key: '4',
			label: t('Last 6 Months'),
			value: 'last_six_months',
		},
	];

	const checkSubmissionStatus = (val) => {
		setSubmissionData(val);
		setSubmissionModal(true);
	};
	const checkVoucher = (receiptData) => {
		// `/upload?prevCamEntryId=${ele.getSubmissionInfo?.receipts[0]?.campaignEntryId}`
		navigate(
			`/upload?prevCamEntryId=${receiptData.getSubmissionInfo?.receipts[0]?.campaignEntryId}`,
			{ state: { receiptData } }
		);
	};

	return (
		<div>
			<div>
				<MainHeader />
			</div>
			<div className="ml-2 mr-2" style={{ paddingBottom: '130px' }}>
				<div className="mt-3">
					<p style={{ fontSize: '25px', fontWeight: '500', textAlign: 'left' }}>
						{t('History')}
					</p>
				</div>

				<div
					className="mt-1"
					style={{ justifyContent: 'left', display: 'flex', gap: '8px' }}
				>
					<Button
						style={{
							backgroundColor: tab === 'Points' ? '#30E700' : '#C2C2C2',
							color: tab === 'Points' ? 'black' : 'white',
							border: 'none',
							fontFamily: 'Hero New',
						}}
						onClick={() => {
							setTab('Points');
							setPoints(true);
						}}
					>
						{t('Points')}
					</Button>

					<Button
						style={{
							backgroundColor: tab === 'Submissions' ? '#30E700' : '#C2C2C2',
							color: tab === 'Submissions' ? 'black' : 'white',
							border: 'none',
							fontFamily: 'Hero New',
						}}
						onClick={() => {
							setTab('Submissions');
							setSubmission(true);
						}}
					>
						{t('Submissions')}
					</Button>
				</div>
				{/* <div
					style={{ position: 'relative', top: '-160px', left: '0', width: '100%' }}
				> */}
				{/* <Whatsapp /> */}
				{/* </div> */}
				<div
					className="mt-1"
					style={{
						display: 'flex',
						justifyContent: 'right',
						alignItems: 'center',
					}}
				>
					<Select
						defaultValue={t('Filter By')}
						className="select-tag text-center"
						onChange={handleFilterChange}
						popupClassName="select-dropdown"
						textalign="center"
					>
						{items.map((item) => (
							<Select.Option key={item.key} value={item.value}>
								{item.label}
							</Select.Option>
						))}
					</Select>
				</div>

				{tab === 'Points' && pointHistory && pointHistory.length
					? pointHistory.map((ele, index) => {
							return (
								<div key={index}>
									<div className="mt-2">
										<div
											style={{
												boxShadow: '0px 2px 18px rgba(0, 0, 0, 0.16)',
												borderRadius: '8px',
											}}
										>
											<div className="text-center" style={{ width: '100%' }}>
												<div className="submission-history-card">
													<div
														className="d-flex"
														style={{ alignItems: 'center' }}
													>
														<div
															style={{
																textAlign: 'left',
																width: '30%',
																display: 'flex',
																justifyContent: 'center',
																alignItems: 'center',
															}}
														>
															<img
																style={{
																	width: '60%',
																	height: '90px',
																	objectFit: 'contain',
																}}
																src={
																	(ele.sourceType === 'CAMPAIGN' &&
																		ele.description === '10 Points') ||
																		(ele.sourceType === 'CAMPAIGN' &&
																		ele.description === '100 Points') ||
																	ele.description === '15 Points' ||
																	ele.description === '20 Points' ||
																	ele.description === '25 Points'
																		? welcome
																		: ele.sourceType === 'REFERRAL'
																		? referral
																		: ele.description ===
																				'additional 0.5x tier bonus' ||
																		  ele.description ===
																				'additional 1x tier bonus' ||
																		  ele.description ===
																				'additional 1x tier bonus' ||
																		  ele.description ===
																				'additional 2x tier bonus'
																		? multiplier
																		: ele.sourceType === 'ORDER'
																		? deliveryIcon
																		: ele.description ===
																		  'points based on receipt amount'
																		? receiptImg
																		: ele.imageurl
																}
																alt=""
															/>
														</div>
														<div style={{ width: '45%' }} className="ml-1">
															<h3 className='title'>
																{ele.description ===
																'points based on receipt amount'
																	? 'Receipt Approved'
																	: ele.sourceType === 'REFERRAL'
																	? 'Referral'
																	: ele.sourceType === 'ORDER'
																	? 'ORDER'
																	: ele.sourceType === 'CAMPAIGN'
																	? ele.description
																	: ele.sourceType}
															</h3>
															<p className="t-black fontSize-14 font-family-hero-new font-weight-400">
																{moment(ele.createdAt).format('DD MMM YYYY')}
																<br />
																{ele.source === 'REGISTER' ? (
																	''
																) : ele.description ===
																  'points based on receipt amount' ? (
																	<>
																		{t('Amount')}: {isSGRegion ? '$' : 'RM'}
																		{ele.receiptAmount}
																	</>
																) : null}
															</p>
														</div>
														<div>
															{ele.operation === 'DEBIT' ? (
																<p
																	style={{
																		color: '#424242',

																		fontWeight: '700',
																		textAlign: 'center',
																	}}
																	className="fontSize-18"
																>
																	-{ele.value} pts
																</p>
															) : ele.operation === 'CREDIT' ? (
																<p
																	style={{
																		color: '#FA00FF',
																		textAlign: 'center',
																		fontWeight: '700',
																	}}
																	className="fontSize-18"
																>
																	+{ele.value} pts
																</p>
															) : null}
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							);
					  })
					: null}

				{tab === 'Points' && pointHistory <= 0 && (
					<div className="mt-5">
						<h3 className="text-center">You have not obtain any points yet</h3>
					</div>
				)}

				{tab === 'Submissions' && uploadHistory <= 0 && (
					<div className="mt-5">
						<h3 className="text-center">
							You have not upload any receipts yet
						</h3>
					</div>
				)}

				{tab === 'Submissions' &&
					uploadHistory &&
					uploadHistory.map((ele, index) => {
						const status =
							ele.getSubmissionInfo.receipts[0]?.status ||
							ele.getSubmissionInfo.status ||
							'Unknown Status';
						let displayStatus;
						switch (status) {
							case 'PENDING':
							case 'IN_REVIEW':
							case 'PROCESSING':
								displayStatus = t('Pending');
								break;
							case 'COMPLETED':
							case 'APPROVED':
								displayStatus = t('Approved');
								break;
							case 'CANCELLED':
							case 'REJECTED':
								displayStatus = t('Rejected');
								break;
							default:
								displayStatus = 'Unknown Status';
						}
						return (
							<div key={index}>
								<div className="mt-2">
									<div
										style={{
											boxShadow: '0px 2px 18px rgba(0, 0, 0, 0.16)',
											borderRadius: '8px',
										}}
									>
										<div className="text-center" style={{ width: '100%' }}>
											<div className="submission-history-card">
												<div
													className="d-flex"
													style={{ justifyContent: '', alignItems: 'center' }}
												>
													<div
														style={{
															textAlign: 'left',
															width: '30%',
															alignItems: 'center',
															justifyContent: 'center',
															display: 'flex',
														}}
														onClick={() => checkSubmissionStatus(ele)}
													>
														<img
															style={{
																width: '100px',
																height: '100px',
																objectFit: 'contain',
															}}
															src={
																ele.imageUrl ? ele.imageUrl : notAvailableImg
															}
															alt="user_submitted_receipt"
															onClick={() => checkSubmissionStatus(ele)}
														/>
													</div>
													<div style={{ width: '50%' }} className="ml-1">
														<h3 className='title'>
															Receipt:{' '}
															{ele?.getSubmissionInfo?.receipts[0]?.invoiceNo ||
																'-'}
														</h3>
														<p
															className="t-black fontSize-14 font-weight-400"
															style={{ textAlign: 'left' }}
														>
															{ele.getSubmissionInfo.createdAt
																? moment(
																		ele.getSubmissionInfo.createdAt
																  ).format('DD MMM YYYY')
																: '-'}
														</p>
														<p
															style={{
																color:
																	displayStatus === t('Pending')
																		? '#BBA802'
																		: displayStatus === t('Approved')
																		? '#35BF54'
																		: displayStatus === t('Rejected')
																		? '#E04040'
																		: 'black',
																fontWeight: '400',
																fontSize: '14px',
															}}
														>
															{displayStatus}
														</p>
													</div>
													<div className="text-center">
														<p
															style={{
																fontWeight: '700',
																color:
																	displayStatus !== 'REJECTED'
																		? '#FA00FF'
																		: 'black',
															}}
															className="fontSize-18 t-pink text-center font-weight-700"
														>
															{isSGRegion ? '$' : 'RM'}{' '}
															{ele.getSubmissionInfo &&
															ele.getSubmissionInfo.totalReceiptAmount > 0
																? typeof ele.getSubmissionInfo
																		.totalReceiptAmount === 'number'
																	? ele.getSubmissionInfo.totalReceiptAmount
																	: (
																			Math.round(
																				ele.getSubmissionInfo
																					.totalReceiptAmount * 100
																			) / 100
																	  ).toFixed(2)
																: '-'}
														</p>

														{/* {displayStatus !== 'REJECTED' ? (
															<p
																style={{ fontWeight: '700' }}
																className="fontSize-16 t-pink text-center"
															>
																RM{' '}
																{ele.getSubmissionInfo.totalReceiptAmount > 0
																	? ele.getSubmissionInfo.totalReceiptAmount
																	: '-'}
															</p>
														) : (
															<p
																style={{
																	color: 'red',
																	width: '100%',
																	display: 'flex',
																	justifyContent: 'center',
																	height: '50px',
																	alignItems: 'center',
																	textAlign: 'center',
																	fontWeight: '600',
																	fontSize: '14px',
																}}
																onClick={() => {
																	checkVoucher(ele);
																}}
															>
																RESUBMIT
																<br />
																AGAIN
															</p>
														)} */}
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						);
					})}
			</div>

			{/* footer nav */}
			<UserFooter type={'submissionsPage'} />

			<div>
				<div>
					<Modal
						open={submissionModal}
						centered
						header={null}
						footer={null}
						closable={false}
						width={320}
					>
						<div className="text-center">
							<img
								src={submissionData?.imageUrl || notAvailableImg}
								alt=""
								style={{ width: '100%', height: '100%' }}
							/>
							{(submissionData?.getSubmissionInfo.receipts[0]?.status ||
								submissionData?.getSubmissionInfo.status) === 'PENDING' ? (
								<p style={{ color: '#BBA802' }} className="text-center">
									{t('Pending')}
								</p>
							) : (
								''
							)}
							{(submissionData?.getSubmissionInfo.receipts[0]?.status ||
								submissionData?.getSubmissionInfo.status) === 'APPROVED' ? (
								<p style={{ color: '#30E700' }} className="text-center">
									{t('Approved')}
								</p>
							) : (
								''
							)}
							{(submissionData?.getSubmissionInfo.receipts[0]?.status ||
								submissionData?.getSubmissionInfo.status) === 'REJECTED' ? (
								<>
									<p style={{ color: '#E04040' }} className="text-center">
										{t('Rejected')}
									</p>
									<p
										style={{
											color: 'black',
											fontWeight: '600',
											fontSize: '12px',
										}}
										className="text-center"
									>
										{submissionData?.getSubmissionInfo.receipts[0]?.remark}
									</p>
								</>
							) : (
								''
							)}
							{(submissionData?.getSubmissionInfo.receipts[0]?.status ||
								submissionData?.getSubmissionInfo.status) === 'ISSUE' ? (
								<p style={{ color: 'black' }} className="text-center">
									Issue
								</p>
							) : (
								''
							)}
							<Button
								className="coming-soon-button mt-1"
								onClick={() => setSubmissionModal(false)}
							>
								Close
							</Button>
						</div>
					</Modal>

					<VoucherUnavailable setView={setView} view={view} />
				</div>
			</div>
		</div>
	);
};

export default Submissions;
